@font-face {
  font-family: "Archivo SemiExpanded";
  src: url("../fonts/ArchivoSemiExpanded-SemiBold.eot");
  src: url("../fonts/ArchivoSemiExpanded-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/ArchivoSemiExpanded-SemiBold.woff2") format("woff2"),
    url("../fonts/ArchivoSemiExpanded-SemiBold.woff") format("woff"),
    url("../fonts/ArchivoSemiExpanded-SemiBold.ttf") format("truetype"),
    url("../fonts/ArchivoSemiExpanded-SemiBold.svg#ArchivoSemiExpanded-SemiBold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Archivo SemiExpanded";
  src: url("../fonts/ArchivoSemiExpanded-Bold.eot");
  src: url("../fonts/ArchivoSemiExpanded-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/ArchivoSemiExpanded-Bold.woff2") format("woff2"),
    url("../fonts/ArchivoSemiExpanded-Bold.woff") format("woff"),
    url("../fonts/ArchivoSemiExpanded-Bold.ttf") format("truetype"),
    url("../fonts/ArchivoSemiExpanded-Bold.svg#ArchivoSemiExpanded-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Archivo SemiExpanded";
  src: url("../fonts/ArchivoSemiExpanded-Regular.eot");
  src: url("../fonts/ArchivoSemiExpanded-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/ArchivoSemiExpanded-Regular.woff2") format("woff2"),
    url("../fonts/ArchivoSemiExpanded-Regular.woff") format("woff"),
    url("../fonts/ArchivoSemiExpanded-Regular.ttf") format("truetype"),
    url("../fonts/ArchivoSemiExpanded-Regular.svg#ArchivoSemiExpanded-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Archivo SemiExpanded";
  src: url("../fonts/ArchivoSemiExpanded-ExtraBold.eot");
  src: url("../fonts/ArchivoSemiExpanded-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/ArchivoSemiExpanded-ExtraBold.woff2") format("woff2"),
    url("../fonts/ArchivoSemiExpanded-ExtraBold.woff") format("woff"),
    url("../fonts/ArchivoSemiExpanded-ExtraBold.ttf") format("truetype"),
    url("../fonts/ArchivoSemiExpanded-ExtraBold.svg#ArchivoSemiExpanded-ExtraBold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
