html {
	overflow-x: hidden;
	overflow-y: scroll;
	&.overflow {
		overflow: hidden;
	}
	background-color: $white;

	color: $dark;
	// &::-webkit-scrollbar {
	// 	width: 0 !important;
	// }
	// overflow: -moz-scrollbars-none;
	// -ms-overflow-style: none;
}

body {
	&.overflow {
		overflow: hidden;
	}

	overflow-x: hidden;
	@include font-secondary-regular;
	background-color: $white;
	color: $dark;
	font-size: $font-size;

	@include break(mobile) {
		font-size: 16px;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	&::-webkit-scrollbar {
		@include break(mobile){
			width: 0 !important;
			overflow: -moz-scrollbars-none;
			-ms-overflow-style: none;
		}
	}



	.grecaptcha-badge {
	    visibility: hidden;
	}
}

body.safari{
	.banner-top-small{
		&:before,
		&:after{
			display: none !important;
		}
	}
}


a{
	color: inherit;
	text-decoration: none;
	transition: .25s;
}

button,
input[type=submit]{
	cursor: pointer !important;
}

.relative{
	position: relative;
}

.loader {
	// position: fixed;
	// top: 0;
	// left: 0;
	// right: 0;
	// height: 100vh;
	// z-index: 9999;
	// z-index: 9999999999;
	// background-color: #ffffff;
	// text-align: center;
}

.container{
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	max-width: 1240px;
}

.container-md{
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	max-width: 1060px;
}

.btn{
	@include font-secondary-medium;
	font-size: 16px;
	background: $green;
	color: $white;
	padding: 14px 35px;
	display: inline-block;
	border-radius: 4px;
	transition: .25s;

	&:first-letter{
		text-transform: uppercase;
	}

	//Color Themes
	&.btn--primary{
		background: $green;
		color: $white;
		border: 1px solid $green;

		&:hover{
			background: $dark-green;
			border-color: $dark-green;
		}
	}

	&.btn--secondary{
		&.dark-bg{
			background-color: transparent;
			color: $white;
			border: 1px solid $white;

			&:hover{
				color: $btn-sec-on-dark-hover;
				border-color: $btn-sec-on-dark-hover;
			}
		}

		&.light-bg{
			background-color: transparent;
			color: $dark;
			border: 1px solid $dark;

			&:hover{
				color: $btn-sec-on-light-hover;
				border-color: $btn-sec-on-light-hover;
			}
		}
	}

	//Sizing variations
	&.menu-btn{
		padding: 15px 24px;
		font-size: 12px;
	}

	&.small{
		padding: 11px 28px;
		font-size: 14px;
	}

	&.big{
		padding: 18px 53px;
		font-size: 20px;
	}

	&.block{
		display: block;
		width: 100%;
		text-align: center;
	}

	&.has-icon{
		padding: 14px 31px 14px 53px;
		position: relative;

		// &:before{
		// 	content: '';
		// 	width: 13px;
		// 	border: 1px solid gold;
		// 	position: absolute;
		// 	left: 24px;
		// 	top: 50%;
		// 	transform: translateY(-50%);
		// }

		.icon-holder{
			content: '';
			width: 14px;
			position: absolute;
			left: 24px;
			top: 50%;
			transform: translateY(-50%);

			img{
				max-width: 100%;
				height: auto;
				display: block;
			}
		}
	}
}

.woocommerce-error{
	background-color: #F15025 !important;
	color: $white !important;
	padding: 20px !important;
	border: none !important;

	&:before{
		display: none !important;
	}
}

img{
	display: block;
}

.img-resp{
	max-width: 100%;
	height: auto;
}

.bg-primary{
	background-color: $dark;
}

.bg-secondary{
	background-color: $pale;
}

.bg-img,
.img-bg{
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.oxo-view {
	// opacity: 0;
	// margin-top: -24px;
	// &.in {
	//   animation: fadeIn 1.3s forwards;
	// }
}

.mobile {
	display: none;
	@include break(mobile) {
		display: block;
	}
}
.desktop {
	@include break(mobile) {
		display: none;
	}
}

.bg_gris {
	background-color: $grey;
	width: 100%;
}


ul, ol{
	@include font-secondary-regular;
}

//TYPOGRAPHY
h1,
h2,
h3,
h4,
h5,
h6{
	@include font-bold;
}

h1, .h1{
	font-size: 40px;
	line-height: 1.2;

	@include break(tablet){
		font-size: 34px;
	}

	@include break(v-mobile){
		font-size: 28px;
	}
}

h2{
	font-size: 32px;
	line-height: 1.2;

	@include break(tablet){
		font-size: 29px;
	}

	@include break(mobile){
		font-size: 26px;
	}
}

h3{
	font-size: 24px;
	line-height: 1.2;
}

h4{
	font-size: 24px;
	line-height: 1.2;

	@include break(mobile){
		font-size: 20px;
	}
}

h5, .h5{
	font-size: 20px;

	@include break(mobile){
		font-size: 18px;
	}
}

h6, .h6{
	font-size: 16px;
}

.subtitle-sm{
	@include font-secondary-regular;
	font-weight: 400 !important;
	font-size: 20px;
}

p, .p{
	@include font-secondary-regular;
	font-size: 16px;
	line-height: 1.6;
}

.p-sm{
	@include font-secondary-regular;
	font-size: 14px;
}

.p-md{
	@include font-secondary-regular;
	font-size: 16px;
}

.p-lg{
	@include font-secondary-regular;
	font-size: 18px;
}

.subtitle-lg{
	@include font-secondary-semibold;
	font-size: 24px;
}

.subtitle-sm{
	@include font-secondary-semibold;
	font-size: 20px;
}

.subtitle-sm-reg{
	@include font-secondary-regular;
	font-size: 20px;
}

.has-wysiwyg{

	a{
		color: $green;

		&:hover{
			color: $dark-green;
		}
	}

	h2{
		margin-bottom: 30px;
		margin-top: 40px;
	}

	h3{
		margin-bottom: 20px;
		margin-top: 30px;
	}

	p{
		margin-bottom: 20px;

		&:last-of-type{
			margin-bottom: 0;
		}
	}

	&.has-btn-holder{
		margin-bottom: 45px;

		@include break(tablet){
			margin-bottom: 30px;
		}

		@include break(v-mobile){
			margin-bottom: 20px;
		}
	}

	ul{
		list-style-position: inside;

		li{
			margin-bottom: 22px;

			@include break(v-mobile){
				margin-bottom: 12px;
			}
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: scale(1.01);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes fadeInO {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.wpcf7-not-valid-tip{
	font-size: 12px !important;
	padding-top: 5px !important;
}

option{
	background: #333;
	color: $white;
}

.has-form, .woocommerce-form{
	div.form-item, .form-row{
		margin-bottom: 28px;

		label{
			font-size: 14px;
			font-weight: 600;
			display: block;
			margin-bottom: 12px;
		}

		input:not([type="submit"]),
		input:not([type="radio"]),
		textarea,
		select{
			background: transparent;
			width: 100%;
			padding: 16px 20px;
			border: 1px solid rgba(7, 6, 11, 0.15);
			border-radius: 4px;
			color: $dark;
			@include font-secondary-regular;
			font-size: 14px;
			resize: none;

			&:focus,
			&:focus-visible,
			&:active{
				border: 1px solid $green;
				outline: none;
				border-radius: 4px;
			}
		}

		select{
			color: rgba(7, 6, 11, 0.4);
			border: 1px solid rgba(7, 6, 11, 0.15);
		}

		::placeholder{
			@include font-secondary-regular;
			font-size: 14px;
			color: $dark;
			opacity: 40%;
		}

		.radios-holder{
			display: flex;

			.radio-item{
				margin-right: 30px;
				display: flex;
				align-items: center;

				& > *{
					font-size: 14px;
					color: $dark;
					font-weight: 400;
					display: block;
				}

				&:last-of-type{
					margin-right: 0;
				}

				input[type="radio"]{
					// width: 15px;
					// height: 15px;
					// margin-right: 8px;
				}

				label{
					white-space: nowrap;
					margin-bottom: 0;
				}
			}


		} //radios-holder

	}
}

.woocommerce-form__input-radio {
    width: auto!important;
    margin-right: 20px;
}

.mailchimp-newsletter {
	.woocommerce form .form-row label.inline {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
	}
	.woocommerce form .form-row .input-checkbox {
		width: 16px;
		margin-left: 20px;
	}
}

.page-template-default .oxo-view {
	padding-top: 120px;
	padding-bottom: 120px;
	@include break(small-screen){
		padding-top: 60px;
		padding-bottom: 60px;
	}
	color: #07060b;
  background: white;

	h2 {
		padding-top: 40px;
		margin-bottom: 20px;
	}
}


.template-landing #mc_embed_shell {
	text-align: left;
	.mc-field-group {
		margin-bottom: 28px;
	}
	p {
		margin-bottom: 0px!important;
	}
	.input-group ul {
		margin-top: 12px;
		list-style-type: none;
		li {
			display: flex;
    		align-items: center;
			label {
				margin-bottom: 0;
			}
		}
	}

	label{
		font-size: 14px;
		font-weight: 600;
		display: block;
		margin-bottom: 12px;
	}

	input:not([type="submit"]),
	input:not([type="radio"]),
	textarea,
	select{
		background: transparent;
		width: 100%;
		padding: 16px 20px;
		border: 1px solid rgba(7, 6, 11, 0.15);
		border-radius: 4px;
		color: $dark;
		@include font-secondary-regular;
		font-size: 14px;
		resize: none;

		&:focus,
		&:focus-visible,
		&:active{
			border: 1px solid $green;
			outline: none;
			border-radius: 4px;
		}
	}

	input[type="radio"] {
		margin-right: 10px;
		height: 17px;
		width: 17px;
		-webkit-appearance: none;
		border-radius: 100%;
		border: 2px solid $gray;
		padding: 0;
	  }

	  input[type="radio"]:checked {
		border-width: 4px;
		border-color: $green;
		border-radius: 100%;
	  }

	  input[type="radio"]:active {
		border: 2px solid #80c537;
		outline: none;
		border-radius: 100%;
	  }
	  
	  input[type="submit"] {
		background-color: $green;
		color: $white;
		font-size: 16px;
		width: 45%;
		display: inline-block;
		margin-top: 40px;
		transition: background-color, 0.5s;
		@include font-secondary-regular;
		&:hover {
		  cursor: pointer;
		  background-color: $dark-green;
		}
	  }

	select{
		color: rgba(7, 6, 11, 0.4);
		border: 1px solid rgba(7, 6, 11, 0.15);
	}

	::placeholder{
		@include font-secondary-regular;
		font-size: 14px;
		color: $dark;
		opacity: 40%;
	}

	.datefield {
		display: flex;
		align-items: center;
		span.subfield {
			padding-right: 10px;
			padding-left: 10px;

			&:first-of-type {
				padding-left: 0;
			}
		}
		input {
			text-align: center;
			width: 70px;
		}
	}

	.asterisk {
		color: $red;
	}
	.indicates-required {
		font-size: 12px;
		margin-bottom: 20px;
		color: $red;
	}
}