.page-single-jobs {
  background: $pale;

  .postule_btn {
    width: 100%;
    padding: 5px;
    margin-top: 10px;
    font-size: 15px;
    text-align: center;
    &:hover {
      cursor: pointer;
    }
  }

  .main-banner {
    padding: 0px;
  }

  .emploi_detail {
    min-height: 500px;
    width: 100vw;
    display: flex;

    @include break(tablet){
      display: block;
    }

    .emplois_left {
      background-color: $white;
      width: 32%;
      padding: 9%;
      width: 490px;

      @include break(small-screen){
        width: 380px;
        padding-right: 62px;
      }

      @include break(tablet){
        width: 100%;
        text-align: center;
        padding-right: 9%;

        .btn{
          width: auto;
        }
      }

      hr {
        margin-top: 50px;
        margin-bottom: 50px;

        @include break(tablet){
          display: none;
        }
      }
      h4 {
        font-size: 13px;
        margin-top: 20px;
        margin-bottom: 20px;
        @include font-extraBold;
      }
      .retourBouton {
        @include font-secondary-semibold;
        color: $green;
        width: 205px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        
        @include break(tablet){
          margin-bottom: 80px;
        }

        @include break(v-mobile){
          margin-bottom: 40px;
        }

        .rond {
          width: 18px;
          height: 18px;
          border: 1px solid $green;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .flecheRetour {
            content: "";
            display: inline-block;
            width: 6px;
            height: 6px;
            border-left: 2px solid $green;
            border-bottom: 2px solid $green;
            margin-left: 3px;
            margin-top: 6px;
            transform-origin: center;
            transform: translateY(-3px) rotate(45deg);
          }
        }
      }
    }
    .emplois_right {
      padding: 10%;
      background-color: $pale;
      // width: 68%;
      flex: 1;

      @include break(small-screen){
        padding-left: 62px;
      }

      @include break(tablet){
        padding: 9% !important;
      }

      hr {
        margin-top: 110px;
        margin-bottom: 110px;

        @include break(tablet){
          margin-top: 60px;
          margin-bottom: 60px;
        }
      }
      strong {
        color: $green;
        font-size: 22px;
        @include font-secondary-bold;

        @include break(mobile){
          font-size: 18px;
        }

      }

      p{
        margin-bottom: 10px;
      }

      ul{
        margin-bottom: 30px;

        li{
          margin-bottom: 5px;
        }
      }
      h2 {
        @include font-extraBold;
        font-size: 30px;
        color: $dark;
        margin-top: 20px;
      }
      h3 {
        @include font-secondary-bold;
        font-size: 14px;
        color: $gray;
      }
      .description {
        margin-top: 40px;
        @include font-regular;
      }

      form {
        .formligne {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .formligne > div {
          width: 45%;
        }
        h2 {
          margin-bottom: 50px;
          font-size: 30px;
          color: $dark;
        }
        label {
          @include font-secondary-bold;
          font-size: 15px;
        }
        input:not([type="radio"]) {
          margin-bottom: 25px;
          margin-top: 10px;
          background: transparent;
          width: 100%;
          padding: 16px 20px;
          border: 1px solid rgba(7, 6, 11, 0.15);
          border-radius: 4px;
          color: #07060b;
          font-family: "Montserrat", sans-serif;
          font-weight: 400;
          font-size: 14px;
          resize: none;
        }
        input::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $gray;
          @include font-secondary-medium;
        }
        input::-moz-placeholder {
          /* Firefox 19+ */
          color: $gray;
          @include font-secondary-medium;
        }
        input:-ms-input-placeholder {
          /* IE 10+ */
          color: $gray;
          @include font-secondary-medium;
        }
        input:-moz-placeholder {
          /* Firefox 18- */
          color: $gray;
          @include font-secondary-medium;
        }
        input[type="radio"] {
          margin-right: 10px;
          height: 17px;
          width: 17px;
          -webkit-appearance: none;
          border-radius: 100%;
          border: 2px solid $gray;
        }

        input[type="radio"]:checked {
          border-width: 4px;
          border-color: $green;
        }
        input[type="submit"] {
          background-color: $green;
          color: $white;
          font-size: 16px;
          width: 45%;
          display: inline-block;
          margin-top: 80px;
          transition: background-color, 0.5s;
          @include font-secondary-regular;
          &:hover {
            cursor: pointer;
            background-color: $dark-green;
          }
        }
        .radio_field {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .radio_group {
          display: flex;
        }
        .label_name_radio {
          margin-bottom: 30px;
        }
        .label_radio {
          margin-right: 30px;
          @include font-secondary-regular;
        }
        input:focus {
          outline: none !important;
          border-color: $green;
        }
      }
    }
  }

  .main-banner {
    height: 60px;
  }

  .jobs-filter-block {
    position: absolute;
    bottom: 80px;
    left: 0;
    width: 100%;

    ul.jobs-filter-list {
      display: flex;
      justify-content: center;

      li {
        color: rgba(7, 6, 11, 0.4);
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 14px 16px;
        margin-right: 17px;
        margin-right: 17px;
        border-radius: 8px;
        transition: 0.3s;

        &.active {
          color: $green;
          background: $white;
          box-shadow: 0px 4px 8px rgba(7, 6, 11, 0.08);
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  section.block-all-jobs {
    padding: 50px 0px;

    .container-md {
      .item {
        margin-bottom: 100px;
        display: flex;



        .category-block {
          max-width: 280px;
          width: 280px;
          margin-right: 50px;
        }

        .jobs-block {
          flex: 1;
        }
      }
    }
  }

  section.spontaneous-candidature {
    padding: 100px 0px;

    .textbox {
      .btn:hover {
        cursor: pointer;
      }
      max-width: 560px;
      margin: auto;
      text-align: center;

      h2 {
        margin-bottom: 40px;
      }
    }
  }
}

table.table-jobs {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  th.filterActive {
    &:after {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      border-left: 2px solid $dark;
      border-bottom: 2px solid $dark;
      margin-left: 10px;
      transform-origin: center;
      transform: translateY(-3px) rotate(-45deg);
    }
  }

  tr {
    // display: block;
    padding: 40px 0;
    border-bottom: 2px solid black;
    cursor: pointer;

    @include break(v-mobile){
      padding: 20px 0;
    }

    th {
      font-size: 14px;
      padding: 40px 0;
      width: 50%;

      @include break(v-mobile){
        padding: 15px 0;
      }
    }

    td {
      padding: 40px 0;

      @include break(v-mobile){
        padding: 20px 0;
      }
    }

    &:hover {
      .arrow {
        img {
          animation: movearrow 1.2s forwards;
        }
      }
    }

    &:first-of-type {
      padding-top: 0;

      th {
        padding-top: 0;
      }
    }
  }

  .arrow {
    width: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    img {
      transition: 0.4s;
    }
  }
}

@keyframes movearrow {
  0% {
    opacity: 40%;
    transform: translateX(-15px);
  }

  50% {
    opacity: 100%;
  }

  100% {
    opacity: 100%;
    transform: translate(0);
  }
}
