
/* -- FUNCTION --*/

/* -- Calculate width in percent -- */
@function calc-width($target) {
  @return ($target / $mockup_container) * 100%;
}
@function calc-percent($target2, $container) {
  @return ($target2 / $container) * 100%;
}

/* -- Calculate font-size in em -- */
@function font-em($font-px) {
  @return ($font-px/$font-size)+em;
}


/* -- Quick Color Contrast -- */
@function darkest( $color ) 	{ @return darken($color,20%); }
@function darker( $color ) 		{ @return darken($color,13.333%); }
@function dark( $color ) 		{ @return darken($color,6.667%); }
@function light( $color ) 		{ @return lighten($color,6.667%); }
@function lighter( $color ) 	{ @return lighten($color,16.333%); }
@function lightest( $color ) 	{ @return lighten($color,30%); }


