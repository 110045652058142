body.home{
	header{
		background: $dark;
		color: $white;

		// > *{
		// 	color: white;
		// }

		svg{
			stroke: $white;
		}
	}

	.burger{
		div{
			background: $white !important;
		}
	}
}


//When hamburger clicked, open navigation
body.open-nav-mob{
	position: fixed;
	width: 100%;

	header.main-header{
		.nav{
			transform: translateX(0);
		}
	}
}


.home {
	header.main-header{
		border-bottom: 1px solid $dark;
	}
}

header.main-header{
	// position: fixed;
	background: $pale;
	color: $dark;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	border-bottom: 1px solid #ebecef;

	@include break(small-screen) {
		position: relative;
	}

	.header-flex {
		height: 80px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
	}

	.logo {
		width: 147px;
		height: auto;

		@include break(small-screen){
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		img{
			max-width: 100%;
			height: auto;
			display: block;
		}
	}

	.nav {

		@include break(small-screen){
			background: white;
			height: calc(100vh - 80px);
			width: 100%;
			max-width: 576px;
			position: absolute;
			top: 100%;
			left: 0;
			transform: translateX(-115%);
			z-index: 1000;
			transition: .4s;
			padding: 40px 0;
			border-radius: 0 8px 8px 0;
			box-shadow: 0px 4px 8px rgba(7, 6, 11, 0.08);
			color: $dark;
			transition: .4s;
			z-index: 999;
			overflow-y: scroll;

		}

		@include break(mobile){
			border-radius: 0;
		}

		@include break(v-mobile){
			padding: 20px 0;
		}

		.btn-holder-mob{ //btn commander
			display: none;

			@include break(small-screen){
				display: block;
				width: 90%;
				padding: 40px 20px;
				text-align: center;
			}

			@include break(v-mobile){
				padding: 20px 0;
			}
		}

		.menu-primary-container,
		.menu-primary-en-container{
			@include break(small-screen){
				width: 90%;
			}


			ul.page-header-menu{
				display: flex;

				@include break(small-screen){
					display: block;
				}

				& > li{
					margin-left: 30px;
					position: relative;
					cursor: pointer;
					white-space: nowrap;

					@include break(small-screen){
						border-bottom: 1px solid rgba(7, 6, 11, 0.4);
						margin-left: calc(10vw / 2);
					}

					&.current_page_item{
						& > a{
							border-color: $green;
						}
					}

					a{
						border-bottom: 1px solid transparent;
						padding-bottom: 4px;

						&:hover{
							border-color: $green;

							@include break(small-screen){
								border-color: transparent;
								color: $green;
							}
						}

						@include break(small-screen){
							font-size: 24px;
							display: block;
							padding: 20px 0;
						}

						@include break(mobile){
							font-size: 14px;
							padding: 10px 0;
						}

					}

					ul.sub-menu{
						min-width: 180px;
						position: absolute;
						top: calc(100% + 15px);
						left: 50%;
						transform: translateX(-50%);
						background: $white;
						padding: 20px 20px 2px 20px;
						border-radius: 8px;
						box-shadow: 0px 4px 8px rgba(7, 6, 11, 0.08);
						z-index: 1;
						transition: .3s;
						opacity: 0;
						visibility: hidden;

						&:before{
							content: '';
							height: 15px;
							width: 100%;
							position: absolute;
							bottom: 100%;
							left: 0;
						}

						@include break(small-screen){
							position: static;
							transform: none;
							padding: 0 10px;
							box-shadow: none;
							opacity: 1;
							visibility: visible;
							display: none;
							padding: 0;
							transition: none;
						}

						& > li{
							margin-bottom: 25px;
							color: $dark;

							@include break(small-screen){
								margin-bottom: 0
							}

							a{
								@include break(small-screen){
									font-size: 18px;
									padding: 10px 0;
									border: none;
								}

								@include break(mobile){
									font-size: 14px;
								}
							}
						}
					}

					&.menu-item-has-children{
						@include break(small-screen){
							position: relative;
						}

						&:hover{
							ul.sub-menu{
								opacity: 1;
								visibility: visible;
								z-index: 10;
							}
						}

						& > a{
							@include break(small-screen){
								position: relative;
							}

							&:before{
								@include break(small-screen){
									content: '';
									border-bottom: 2px solid $dark;
									border-right: 2px solid $dark;
									width: 8px;
									height: 8px;
									transform-origin: center;
									position: absolute;
									right: 8px;
									top: 50%;
									transition: .3s;
									transform: translateY(-50%) rotate(45deg);
								}
							}

							&.is-open{
								&:before{
									transform: translateY(-50%) rotate(225deg);
								}
							}
						}
					}
				}
			}
		} // ul.menu-primary-container
	} //.nav

	.nav_mobile {
		display: none;
		position: relative;
		cursor: pointer;

		@include break(small-screen) {
			display: block;
		}

		.burger {
			height: 26px;
			width: 30px;
			display: flex;
			justify-content: space-around;
			flex-direction: column;
			position: relative;

			div {
				position: absolute;
				background-color: $dark;
				width: 100%;
				height: 2px;
				transition: .5s;

				left: 0;

				&:nth-of-type(1){
					top: 0;
				}

				&:nth-of-type(2){
					top: 24px;
				}

				&:nth-of-type(3){
					top: 50%;
					transform: translateY(-50%);
				}

			}
		}
		.close {
			opacity: 0;
			position: absolute;
			top: -10px;
			left: 7px;
			font-size: 35px;
			line-height: 41px;
		}

		&.is-open{
			.burger{

				div{
					top: 50%;
					left: 50%;

					&:nth-of-type(1){
						transform: translate(-50%, -50%) rotate(45deg);
					}

					&:nth-of-type(2){
						opacity: 0;
						visibility: hidden;
					}

					&:nth-of-type(3){
						transform: translate(-50%, -50%) rotate(-45deg);
					}
				}
			}
		}
	}
}

.nav_right_side{
	display: flex;
	align-items: center;

	.btn{ //btn commander
		@include break(small-screen){
			display: none;
		}
	}

	.icon-holder{
		width: 40px;
		height: 40px;
		position: relative;
		transition: .2s;
		cursor: pointer;

		&.cart-icon{
			margin-right: 28px;

			@include break(small-screen){
				margin-right: 0;
			}

			.cart-count{
				background: $green;
				color: $white;
				width: 16px;
				height: 16px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 9px;
				font-weight: 500;
				position: absolute;
				right: 3px;
				bottom: 3px;
				border: 1px solid $pale;
				border-radius: 50%;
				line-height: 0.8em;
				z-index: 1;
			}
		}

		&.account-icon{
			border-radius: 50%;

			&:hover,
			&.active{
				background: rgba(7, 6, 11, 0.05);
			}
		}

		svg{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.mon_compte_section {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-right: 12px;

		@include break(v-mobile){
			margin-right: 0;
		}

		&:hover{
			.mon-compte-popup,
			.arrow{
				opacity: 1 !important;
				visibility: visible !important;
			}
		}

		.arrow{
			content: '';
			display: block;
			width: 20px;
	        height: 20px;
	        // border-left: 19px solid transparent;
	        // border-right: 19px solid transparent;
	        // border-bottom: 19px solid $white;
	        background: white;
	        position: absolute;
	        bottom: calc(100% - 10px);
	        top: 78px;
	        left: 50%;
	        transform-origin: center;
	        transition: .3s;
	        transform: translateX(-50%) rotate(-45deg);
	        box-shadow: 0px 4px 8px rgba(7, 6, 11, 0.08);
	        opacity: 0;
	        visibility: hidden;
		}

		.mon-compte-popup{
			color: $dark;
			min-width: 290px;
			background: white;
			position: absolute;
			border-radius: 8px;
			top: calc(120% + 40px);
			left: 50%;
			transform: translateX(-50%);
			z-index: 9999;
			transition: .3s;
			opacity: 0;
			visibility: hidden;

			box-shadow: 0px 4px 8px rgba(7, 6, 11, 0.08);



			&:before{
				content: '';
				position: absolute;
				bottom: 100%;
				left: 50%;
				transform: translateX(-50%);
				width: 140px;
				height: 50px;
				display: block;
			}

			button.close-btn{
				display: none;
			}

			.panel-header{
				position: relative;
				padding: 20px;
				border-bottom: 1px solid rgba(7, 6, 11, 0.05);

				p{
					font-size: 10px;
					text-transform: uppercase;
					font-weight: 700;
				}

				// .close-btn{
				// 	width: 14px;
				// 	height: 14px;
				// 	position: absolute;
				// 	top: 50%;
				// 	right: 20px;
				// 	transform: translateY(-50%) rotate(45deg);
				// 	display: block;
				// 	transition: .3s;
				// 	background: none;
				// 	border: none;

				// 	&:before,
				// 	&:after{
				// 		content: '';
				// 		background: $dark;
				// 		height: 2px;
				// 		width: 100%;
				// 		position: absolute;
				// 		left: 0;
				// 		top: 50%;
				// 		display: block;

				// 	}

				// 	&:before{
				// 		transform: translateY(-50%) rotate(90deg);
				// 	}

				// 	&:after{
				// 		transform: translateY(-50%);
				// 	}
				// }
			}

			.panel-content{
				padding: 30px 20px;
				text-align: center;

				& > *{
					font-size: 12px;
				}

				.connect-btn{
					@extend .btn;
					margin-bottom: 22px;
					font-size: 14px;
				}

				a{
					@include font-semiBold;
					color: $green;

					&:hover{
						color: $dark-green;
					}
				}
			}
		}
	}
}

header .alert {
	display: none;
	position: fixed;
	top:0;
	left: 0;
	right: 0;
	background-color: #b00c2f;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	font-size: 14px;
	color: $white;
	z-index: 9999;
	@include break(small-screen) {
		padding-top: 15px;
		padding-bottom: 15px;
		font-size: 12px;
		.container {
			padding-right: 2.5%;
			padding-left: 2.5%;
		}
	}
	@include break(mobile) {
		.container {
			padding-right: 40px;
			padding-left: 40px;
			width: 100%;
		}
	}

	.close {
		cursor: pointer;
		position: absolute;
		right: 5%;
		top:50%;
		transform: translateY(-50%);
		@include break(mobile) {
			right: 5px;
			transform: translate(-50%,-50%);
		}
	}

	&.green {
		background-color: $green;
	}
}
