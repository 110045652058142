.page-remercions{
	background: $white;
	padding: 60px 110px;
	border-radius: 8px;

	@include break(tablet) {
		padding: 60px;
	}

	@include break(mobile) {
		padding: 30px;
	}

	@include break(v-mobile) {
		padding: 50px 10px;
	}

	.section-header{
		max-width: 550px;
		margin: 0 auto 60px;
		text-align: center;

		.icon-holder{
			width: 63px;
			height: 58px;
			background: #F8F9FB;
			border-radius: 35%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 auto 32px;
		}

		h2{
			margin-bottom: 12px;
		}
	}

	.info_total{
		padding: 20px 0;
		margin-bottom: 50px;
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;

		.flex{
			display: flex;
			flex-wrap: wrap;

			.item{
				width: 50%;
				margin-bottom: 27px;

				@include break(mobile) {
					width: 100%;
				}

				.title{
					font-size: 14px;
					margin-bottom: 8px;
					font-weight: 400;
				}

				p{
					font-size: 16px;
					font-weight: 600;
				}
			}
		}
	}

	table{
		width: 100%;
		border-collapse: collapse;
		margin-bottom: 40px;

		tr{
			padding: 40px 0;
			border-bottom: 2px solid rgba(7, 6, 11, 0.05);

			th{
				color: rgba(7, 6, 11, 0.4);
				font-size: 10px;
				text-transform: uppercase;
				text-align: left !important;
				padding-bottom: 10px;
			}
		}

		td{
			padding: 35px 0;
		}

		.bold{
			font-weight: 600;
			font-size: 16px;

			@include break(mobile){
				font-size: 13px;
			}
		}

		.product_description_image {
			display: flex;

			@include break(v-mobile){
				width: auto;
				padding-right: 20px;
			}

			.img-holder{
				width: 80px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 15px;

				@include break(v-mobile) {
					width: 50px;
					margin-right: 8px;
				}

				img{
					max-width: 65px;
					height: auto;
					max-height: 80px;
					display: block;
				}
			}

			.product-name{
				font-size: 16px;
				margin-bottom: 4px;
				font-weight: 600;
				max-width: 170px;
				white-space: break-spaces;

				@include break(mobile){
					font-size: 12px;
				}
			}

			.product-quant{
				font-size: 12px;
				font-weight: 500;
			}
		}
	}

	.price-details{
		margin-bottom: 40px;

		hr{
			margin-bottom: 20px;
		}

		.item{
			display: flex;
			justify-content: space-between;
			margin-bottom: 18px;

			p{
				font-size: 16px;

				&.value{
					font-weight: 600;
				}
			}
		}
	}

	.store-info{
		background: rgba(7, 6, 11, 0.05);
		padding: 28px;
		border-radius: 8px;
		margin-bottom: 60px;

		h5{
			margin-bottom: 40px;
			font-weight: 600;
		}

		.flex{
			display: flex;
			justify-content: space-between;

			@include break(v-mobile){
				display: block;
			}
		}

		.name-and-location,
		.timetable{
			display: flex;
			width: calc((100% - 60px ) / 2);

			@include break(v-mobile){
				width: 100%;
			}

			.icon-holder{
				width: 13px;
				margin-right: 17px;
			}

			.txt-side{

				.txt-title{
					font-size: 14px;
					font-weight: 600;
					margin-bottom: 8px;
				}

				.location{
					font-size: 12px;
				}
			}
		}

		.name-and-location{
			margin-bottom: 22px;

			@include break(v-mobile){
				margin-bottom: 30px;
			}
		}
	}


	.btn-holder{
		text-align: center;
	}
}







