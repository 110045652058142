
//BANNER TOP (SMALL)
//ON PAGE ARCHIVE PRODUCT (FRESH and DRY)
.banner-top-small{
	// min-height: 460px;
	min-height: 320px;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	.hide-desktop {
		visibility: hidden;
	}

	@include break(tablet){
		background-position-x: 34%;
	}

	@include break(mobile){
		display: block;
		background-image: none !important;
		.hide-desktop {
			display: block;
			visibility: visible;
		}
	}

	@include break(tablet){
		background-position-x: 16%;
	}

	&:before,
	&:after{
		// content: '';
		// background: rgb(7,6,11);
		// height: 100px;
		// width: 100%;
		// position: absolute;
		// left: 0;
	}

	&:before{
		top: 0;
		background: linear-gradient(180deg, rgba(7,6,11,1) 0%, rgba(255,255,255,0) 100%);

		@include break(mobile){
			display: none;
		}
	}

	&:after{
		bottom: 0;
		background: linear-gradient(0deg, rgba(7,6,11,1) 0%, rgba(255,255,255,0) 100%);

		@include break(mobile){
			bottom: auto;
			top: 253px;
		}
	}

	.container-md{
		width: 100%;
	}

	.flex-parent{
		display: flex;

		@include break(mobile){
			display: block;
		}

		.img-side,
		.txt-side{
			width: 50%;

			@include break(mobile){
				width: 100%;
			}
		}

		.img-side{
			height: 0;

			@include break(mobile){
				height: 400px;
				background-size: cover;
				background-position: center center;
			}
		}

		.txt-side{
			padding: 0 55px;

			@include break(mobile){
				padding-top: 80px;
				padding-left: 0;
				width: 90%;
				margin: 0 auto;
			}

			@include break(v-mobile){
				padding-top: 10px;
				padding-right: 0;
			}
		}
	}
}


//SECTION MAIN BANNER
//BANNER WITH TEXT CENTER AND SPECIAL CHARACTER
//Ex. Page franchise, faq, carriere...
.main-banner.centered-sp-char{
	// height: calc(100vh - 80px);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 220px 0;

	@include break(tablet){
		padding: 140px 40px;
	}

	@include break(mobile){
		height: auto;
		padding: 100px 40px;
	}

	.breadcrumb-theme{
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.textbox{
		max-width: 693px;
		text-align: center;
		position: relative;

		@include break(mobile){
			max-width: 600px;
		}

		@include break(v-mobile){
			max-width: 500px;
		}

		& > *{
			margin-left: auto;
			margin-right: auto;
			position: relative;
		}

		.special-char{
			width: 70%;
			max-width: 437px;
			position: absolute;
			bottom: -40px;
			left: 50%;
			transform: translateX(-50%);

			img{
				max-width: 100%;
				height: auto;
				display: block;
			}

		}

		h1{
			max-width: 850px;
			margin-bottom: 22px;
		}

		.description,
		p{
			@extend .p-lg;
			margin-bottom: 40px;
		}

		a.btn{
			font-size: 16px;
			padding: 15px 35px;
			position: relative;
		}
	}
}


//CTA BANNER (LARGE)
.cta-banner{
	text-align: center;

	&.cta-banner-1{
		padding: 180px 0;
		color: $white;
		position: relative;
		background-position: center;

		@include break(mobile){
			padding: 160px 0;
		}

		&:before{
			content: '';
			background: #1e1e1f;
			opacity: 35%;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}

		.icon-holder{
			background: $pale;
			width: 63px;
			height: 58px;
			margin-bottom: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 38%;
			margin-left: auto;
			margin-right: auto;

			@include break(mobile){
				width: 55px;
				height: 50px;
				margin-bottom: 22px;
			}
		}

		.textbox{
			max-width: 660px;
			margin: 0 auto;
			position: relative;

			@include break(mobile){
				max-width: 400px;
			}

			.banner-title{
				margin-bottom: 80px;

				@include break(mobile){
					margin-bottom: 60px;
				}
			}
		}
	}
}

//CTA BANNER (SMALL)
.cta-banner-small{
	padding: 120px 0;

	& > *{
		color: white;
	}

	.parent-flex{
		display: flex;
		justify-content: space-between;

		@include break(small-screen){
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	.txt-side{
		margin-right: 100px;
		display: flex;
		align-items: center;

		@include break(small-screen){
			width: 100%;
			margin-bottom: 40px;
			margin-right: 0;
			justify-content: center;
		}

		@include break(mobile){
			display: block;
			margin-bottom: 28px;
		}

		.icon-holder{
			background: $pale;
			width: 63px;
			height: 58px;
			margin-right: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 38%;

			@include break(mobile){
				width: 55px;
				height: 50px;
				margin: 0 auto 10px;
			}
		}
	}
}

//IMG-TXT-BLOCK
//HAS IMAGE VERTICAL OR HORIZONTAL
.img-txt-block{
	padding: 160px 0;

	@include break(tablet){
		padding: 100px 0;
	}

	@include break(mobile){
		padding: 90px 0;
	}

	@include break(v-mobile){
		padding: 30px 0;
	}


	.relative-parent{
		position: relative;

		@include break(v-mobile){
			position: static;
		}
	}

	.img-side{
		height: 800px;
		width: 620px;

		@include break(tablet){
			height: 650px;
			width: 550px;
		}

		@include break(mobile){
			width: 90%;
			height: 92vw;
		}

		@include break(v-mobile){
			width: 100%;
			height: 425px;
		}
	}

	.txt-side{
		width: 512px;
		background: white;
		color: $dark;
		padding: 90px 75px;
		border-radius: 8px;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);

		@include break(tablet){
			padding: 70px 55px;
			width: 450px;
		}

		@include break(v-mobile){
			padding: 40px 22px;
			position: static;
			width: 100%;
			transform: translateY(-14px);
		}

		.txt-holder{
			max-width: 294px;
		}

		.btn-holder{
			display: flex;

			@include break(v-mobile){
				display: block;
			}

			a.btn{

				@include break(v-mobile){
					display: table;
				}

				&:first-of-type{
					margin-right: 16px;

					@include break(v-mobile){
						margin-right: 0;
						margin-bottom: 10px;
					}
				}
			}
		}
	}

	//IMAGE ON THE RIGHT
	&.inverted{

		.txt-side{
			position: relative;
			transform: none;

			@include break(v-mobile){
				padding: 40px 22px;
				width: 100%;
				transform: translateY(-14px);
			}
		}

		.img-side{
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);

			@include break(mobile){
				width: 90%;
				height: 92vw;
			}

			@include break(v-mobile){
				position: static;
				width: 100%;
				height: 425px;
				transform: none;
			}
		}
	}

	&.img-horizontal{

		.img-side{
			height: 643px;
			width: 840px;

			position: relative;
			left: -100px;

			@include break(v-mobile){
				position: static;
				width: 100%;
				height: 425px;
				transform: none;
			}
		}
	}
}

//BREADCRUMB ON THEME
.breadcrumb-theme{
	padding: 20px 0;

	.bc-list{
		list-style: none;
		display: flex;
	}

	& > *{
		font-size: 12px;
		color: $dark;
		opacity: 40%;
	}

	li{
		margin-right: 20px;
		position: relative;

		&:before{
			content: '';
			width: 2px;
			height: 2px;
			background: $dark;
			opacity: 40%;
			border-radius: 50%;
			position: absolute;
			right: -10px;
			top: 50%;
			transform: translateY(-50%);
		}

		a{
			&:hover{
				opacity: 100% !important;
				text-decoration: underline;
			}
		}

		&:last-of-type{
			&:before{
				display: none;
			}
		}

		ul li{
			margin-right: 0;
		}
	}


}


//SECTION CONTACT
//Ex: Page franchise, page B2B..
section.contact-section{
	padding: 150px 0;

	@include break(tablet){
		padding: 100px 0;
	}

	@include break(mobile){
		padding: 80px 0;
	}


	.parent-flex{
		display: flex;
		justify-content: space-between;

		@include break(v-mobile){
			display: block;
		}

		.left-side{
			width: 220px;
			padding-right: 30px;

			@include break(tablet){
				margin-right: 30px;
			}

			@include break(v-mobile){
				width: 100%;
				margin-bottom: 80px;
			}

			.section-title{
				padding-bottom: 20px;
				border-bottom: 1px solid $black;
				margin-bottom: 60px;
			}

			.item{
				margin-bottom: 40px;

				.title{
					@include font-secondary-regular;
					font-size: 12px;
					margin-bottom: 15px;
					text-transform: uppercase;
				}

				p{
					@include font-secondary-bold;
					font-size: 16px;
				}
			}
		}

		.right-side{
			flex: 1;
			max-width: 620px;

			@include break(v-mobile){
				width: 100%;
			}

			.block-title{
				margin-bottom: 40px;
			}

			form{
				.btn-holder{
					margin-top: 40px;
				}
			}
		}
	}
}
