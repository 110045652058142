.page-home-jobs{
	background: $pale;


	//SECTION MAIN IMAGE
	.main-image{
		height: 670px;
		background-position: center;

		@include break(tablet){
			height: 50vw;
		}
	}

	// section.our-mission{
	// 	overflow-x: hidden;
	// 	padding: 120px 0;

	// 	.section-header{
	// 		max-width: 730px;
	// 		margin-bottom: 120px;

	// 		h2{
	// 			margin-bottom: 40px;
	// 		}
	// 	}

	// 	.highlight-quote{
	// 		margin-left: auto;
	// 		margin-bottom: 218px;

	// 		@include break(tablet){
	// 			margin-bottom: 160px;
	// 		}
			
	// 		.text-box{
	// 			display: flex;
	// 			justify-content: flex-end;
	// 			align-items: center;
				
	// 			h4{
	// 				max-width: 450px;
	// 			}
	// 		}

	// 		.icon-holder{
	// 			width: 150px;
	// 			margin-right: 35px;
	// 			position: relative;

	// 			.icon-item{
	// 				position: absolute;
	// 				top: 50%;
	// 				left: 50%;
	// 				transform: translate(-50%, -50%);
	// 				max-width: 77px;
	// 				max-height: 80px;
	// 			}
	// 		}
	// 	}

	// 	.gallery{
	// 		padding: 80px 0;
	// 		position: relative;

	// 		.item{

	// 			&.img-left-top{
	// 				width: 440px;
	// 				height: 330px;
	// 				position: absolute;
	// 				top: 0;
	// 				left: -100px;

	// 			}

	// 			&.img-left-btm{
	// 				width: 400px;
	// 				height: 485px;
	// 				position: absolute;
	// 				left: 0;
	// 				bottom: 0;
	// 			}

	// 			&.img-right{
	// 				width: 622px;
	// 				height: 751px;
	// 				margin-left: auto;
	// 				position: relative;
	// 				right: -100px;
	// 			}
	// 		}
	// 	}
	// }

	section.work-at-mito{
		padding: 40px 0;
		// overflow-x: hidden;

		.section-header{
			max-width: 730px;
			margin-bottom: 120px;

			@include break(tablet){
				margin-bottom: 80px;
			}

			@include break(v-mobile){
				margin-bottom: 40px;
			}

			h2{
				margin-bottom: 40px;

				@include break(v-mobile){
					margin-bottom: 30px;
				}
			}
		}

		.highlight-quote{
			margin-left: auto;
			margin-bottom: 218px;

			@include break(tablet){
				margin-bottom: 160px;
			}

			@include break(mobile){
				margin-bottom: 110px;
			}

			@include break(v-mobile){
				margin-bottom: 80px;
			}
			
			.text-box{
				display: flex;
				justify-content: flex-end;
				align-items: center;
				
				h4{
					max-width: 450px;
				}
			}

			.icon-holder{
				width: 150px;
				margin-right: 35px;
				position: relative;

				@include break(mobile){
					width: 120px;
					min-width: 120px;
				}

				@include break(v-mobile){
					width: 90px;
					min-width: 90px;
				}

				.icon-item{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					max-width: 77px;
					max-height: 80px;
				}
			}
		}

		.gallery{
			padding-top: 80px;
			padding-bottom: 442px;
			position: relative;

			@include break(mobile){
				padding-bottom: 350px;
			}

			@include break(v-mobile){
				padding: 0;
			}

			.item{

				&.img-left{
					width: 400px;
					height: 487px;

					@include break(tablet){
						width: 36vw;
						height: 50vw;
					}

					@include break(v-mobile){
						width: 80%;
						height: 400px;
						margin: 0 auto;
					}
				}

				&.img-right-top{
					width: 400px;
					height: 303px;
					position: absolute;
					right: 0;
					top: 0;

					@include break(tablet){
						width: 40vw;
						height: 30vw;
					}

					@include break(v-mobile){
						display: none;
					}
				}

				&.img-right-btm{
					width: 622px;
					height: 475px;
					position: absolute;
					right: -300px;

					@include break(tablet){
						width: 40vw;
						height: 53vw;
						right: -39px;
					}

					@include break(v-mobile){
						display: none;
					}
				}
			}
		}
	}

	section.advantages{
		padding: 120px 0 80px 0;

		@include break(tablet){
			padding: 80px 0;
		}

		@include break(v-mobile){
			padding: 40px 0;
		}

		.section-header{
			margin-bottom: 60px;

			@include break(v-mobile){
				margin-bottom: 30px;
			}
		}

		.section-content{
			ul.list-advantages{
				display: flex;
				flex-wrap: wrap;

				margin: 0 -35px;

				@include break(v-mobile){
					margin: 0;
				}

				li{
					width: calc(100% / 3);
					padding: 0 35px;
					margin-bottom: 40px;

					@include break(mobile){
						width: calc(100% / 2);
						padding: 0 25px;
						margin-bottom: 30px;
					}

					@include break(v-mobile){
						width: 100%;
						// max-width: 350px;
						// margin: auto;
						// text-align: center;
						margin-bottom: 20px;
						padding: 0;
					}
				}

				.card{
					text-align: center;

					@include break(v-mobile){
						display: flex;
						text-align: left;
					}

					.icon-holder{
						min-width: 61px;
						height: 61px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: 5px;

						@include break(v-mobile){
							margin-right: 25px;
							align-items: flex-start;
						}

						img{
							max-width: 45px;
							max-height: 42px;
						}
					}
				}
			}
		}
	}

	section.slider-testimonials{
		padding-top: 30px;
		padding-bottom: 100px;

		border: 9px solid blue;

		// margin-bottom: 0 !important;
		margin-bottom: 100px;

		.slider-testimonials-holder{
			text-align: center;
			border: 4px solid cyan;
		}
	}

	.cta-banner-small{
		.parent-flex{
			justify-content: center;
		}
	}
}














