.voir_plus_btn:hover {
  cursor: pointer;
  background-color: $white;
  color: $black;
}

.woocommerce #content div.product div.images,
.woocommerce div.product div.images,
.woocommerce-page #content div.product div.images,
.woocommerce-page div.product div.images {
  float: none !important;
}

.woocommerce #content div.product div.summary,
.woocommerce div.product div.summary,
.woocommerce-page #content div.product div.summary,
.woocommerce-page div.product div.summary {
  float: none !important;
}

.woocommerce-product-details__short-description {
  a {
    color: $green;
    text-decoration: underline;

    &:hover {
      color: $dark-green;
    }
  }
}

body.single-product {
  #voir_plus_product_button {
    display: none;
  }
  .content-area {
    background: $dark;
    //background: grey; //temporary;
    color: $white;
  }

  .woocommerce-breadcrumb {
    padding: 20px 0;
    background: $dark;
    margin-bottom: 0;

    > * {
      @include font-secondary-regular;
      font-size: 12px;
      color: $pale;
    }

    a {
      margin-right: 20px;
      position: relative;
      transition: 0.25s;

      &:hover {
        color: #f8f9fb;
      }

      &:before {
        content: "";
        width: 2px;
        height: 2px;
        background: $pale;
        border-radius: 50%;
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .product_info {
    .parent-flex {
      display: flex;

      @include break(tablet) {
        display: block;
      }

      .div_product_image,
      .product_detail_info {
        width: 50%;

        @include break(tablet) {
          width: 100%;
        }
      }

      .div_product_image {
        // border: 3px solid red;
        position: relative;

        @include break(tablet) {
          display: flex;
          padding: 60px 0;
          justify-content: center;
          align-items: center;
          position: static;
        }

        .product-img-main {
          width: 315px;
          height: 315px;
          // border: 2px solid blue;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          @include break(tablet) {
            position: relative;
            transform: none;
            left: auto;
            top: auto;
            width: 280px;
            height: auto;
          }

          @include break(mobile) {
            width: 240px;
          }

          @include break(v-mobile) {
            width: 200px;
          }

          img {
            max-width: 100%;
            height: auto;
            display: block;
          }

          .ingredient-left,
          .ingredient-right {
            position: absolute;
            width: 140px;
            height: 140px;
            bottom: -20px;
            // border: 2px solid green;
          }

          .ingredient-left {
            left: -40px;
          }

          .ingredient-right {
            right: -40px;
          }
        }
      } //.div_product_image

      .product_detail_info {
        .summary.entry-summary {
          //THIS NEEDS TO BE RE-STRUCTURED THE HTML
          width: 100%;
          border-bottom: 1px solid $white;
          .stock.out-of-stock {
            display: none;
          }
        }

        .mito_add_to_cart_button.disabled {
          background: $btn-sec-on-light-hover;
          border: 1px solid $btn-sec-on-light-hover;
          margin-top: 20px;
          pointer-events: none; /* Empêche les événements de la souris, rendant le bouton non cliquable */
          cursor: not-allowed; /* Affiche le curseur en mode désactivé */
        }

        .product_title.entry-title {
          margin-bottom: 20px;
        }

        .woocommerce-product-details__short-description {
          padding-bottom: 15px;
        }

        .woocommerce-tabs {
          border-bottom: 1px solid $white;
          width: 100%;

          ul.tabs {
            margin-left: 0;
            padding-left: 0;

            &:before,
            &:after {
              display: none !important;
            }

            li {
              background: transparent;
              padding: 0;
              margin: 0;
              margin-right: 10px;
              text-transform: uppercase;
              font-size: 13px;
              border: none;
              letter-spacing: 0.1em;

              &:before,
              &:after {
                display: none !important;
              }
            }
          }
        }

        .woocommerce-Tabs-panel {
          @include font-secondary-regular;
          font-size: 15px;

          p {
            font-size: 15px;
          }
        }

        .card-btn {
          padding: 28px 0;
        }
      }
    }
  } //.product_info

  //TAGS (Ex: Epice, Vegetarien, etc)
  .tag_list {
    margin-bottom: 15px;
  }

  //QUANTITY (Grams or pieces)
  .productPrice {
    margin-left: auto;
    font-size: 32px;
    font-weight: 600;
  }

  .variation_choice_price {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .sale_price_box {
      right: 0;
      bottom: 0;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      p {
        @include font-secondary-semibold;
        font-size: 26px;
        color: $white;

        &.salePrice {
          color: $green;
        }

        &.discountPrice {
          font-size: 14px;
          font-weight: 400;
          text-decoration: line-through;
        }
      }
    }
  } //.variation_choice_price

  .bulle_quantity {
    @include font-secondary-medium;
    background-color: transparent;
    color: $white;
    margin-right: 8px;
    padding: 4px 8px;
    font-size: 12px;
    border: 1px solid $white;
    border-radius: 4px;
    transition: 0.25s;
    cursor: pointer;
  }

  .bulle_quantity_active {
    background-color: $white;
    color: $dark;
  }

  //INFORMATIONS ABOUT THE PRODUCT
  .conseil_box {
    margin-top: 30px;

    .conseil {
      background-color: rgba(255, 255, 255, 0.05);
      // width: 480px;
      padding: 23px 23px 23px 84px;
      border-radius: 4px;
      position: relative;

      p {
        @include font-secondary-semibold;
        font-size: 14px;
      }

      a {
        color: $green;
        text-decoration: underline;
      }

      &:before {
        content: url(../img/icon_conseil.svg);
        position: absolute;
        left: 29px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  //SECTION
  .histoire_chef {
    padding: 160px 0;

    @include break(tablet) {
      padding: 120px 0;
    }

    @include break(v-mobile) {
      padding: 90px 0;
    }

    .text-animation {
      padding: 20px 0;
    }

    .text-loop {
      margin-right: 10vw;
    }
  }

  //SECTION HISTORY-CHEF (TXT + IMG)
  section.chef-history {
    padding: 80px 0 288px;
    border-bottom: 1px solid $white;

    @include break(tablet) {
      padding-bottom: 200px;
    }

    @include break(mobile) {
      padding-bottom: 100px;
    }

    .parent-flex {
      display: flex;
      justify-content: space-between;

      @include break(tablet) {
        display: block;
      }

      .left-side {
        width: calc((100% - 160px) / 2);
        // flex: 1;
        padding-top: 170px;
        max-width: 500px;

        @include break(small-screen) {
          padding-top: 100px;
        }

        @include break(tablet) {
          width: 100%;
          padding-top: 0;
          margin-bottom: 100px;
        }

        @include break(v-mobile) {
          margin-bottom: 50px;
        }

        h2 {
          margin-bottom: 22px;
        }
      }

      .right-side {
        min-width: 512px;
        position: relative;

        @include break(small-screen) {
          min-width: 0;
          width: 43vw;
          height: 62vw;
        }

        @include break(tablet) {
          margin-left: auto;
          margin-right: 60px;
        }

        .main-img,
        .second-img {
          background-size: cover;
          background-position: center;
        }

        .main-img {
          height: 700px;

          @include break(small-screen) {
            height: 63vw;
          }

          @include break(tablet) {
            position: relative;
          }
        }

        .second-img {
          width: 400px;
          height: 270px;
          position: absolute;
          left: -218px;
          bottom: -135px;

          @include break(tablet) {
            width: 40vw;
            height: 24vw;
            bottom: -50px;
            left: -100px;
          }
        }
      }
    }
  } //section.chef-history


  .related.products,
  .product_related,
  .up-sells.products {
    //upsells might be removed
    padding: 80px 0;
    border-top: 1px solid $white;

    h4 {
      @include font-secondary-regular;
      margin-bottom: 40px;
    }

    .woocommerce-loop-product__title {
      padding-bottom: 20px!important;
    }

    .sale_price_box {
      position: absolute;
      left: 25px;
      bottom: 25px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @include break(mobile) {
        position: initial;
      }
    }

    ul.products {
      display: flex;
      justify-content: flex-start !important;
      flex-wrap: wrap;

      @include break(mobile) {
        justify-content: space-between !important;
      }

      @include break(mobile) {
        display: block !important;
      }
    }

    li.product {
      width: 25% !important;
      padding: 0 18px;
      background-color: rgba(255, 255, 255, 0.04);
      color: white;
      min-height: 434px;
      // border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 8px;
      max-width: 295px !important;
      padding: 25px !important;
      padding-top: 25px !important;
      position: relative !important;
      padding-bottom: 100px !important;
      float: none !important;

      @include break(small-screen) {
        width: calc(100% / 3) !important;
      }

      @include break(tablet) {
        width: calc((100% - 30px) / 2) !important;
      }

      @include break(mobile) {
        width: 100% !important;
        max-width: 330px !important;
        // display: flex !important;
        padding: 20px !important;
        min-height: 0;
      }

      &:nth-of-type(4) {
        @include break(small-screen) {
          display: none;
        }
      }

      &:nth-of-type(3) {
        @include break(mobile) {
          display: none;
        }
      }
    }

    &.in_cart {
      border-color: $green;

      .attachment-woocommerce_thumbnail {
        filter: blur(2px);
        position: relative;
      }
    }

    .tag_list {
      width: 100%;
      position: absolute;
      padding: 25px 25px 0;
      top: 0;
      left: 0;

      .mito_product_tag.sale_tag {
        top: 25px;
        right: 25px;
      }
    }

    li.product a img {
      max-width: 146px !important;
      height: auto;
      margin: 35px auto !important;
    }

    //TITLE
    li.product .woocommerce-loop-category__title,
    li.product .woocommerce-loop-product__title,
    li.product h3 {
      @extend .h5;
      padding: 0;
      margin-bottom: 14px;
    }



    //QUANTITY
    .variation_choice_price {
      margin-bottom: 20px;
      display: flex;
      @include break(tablet) {
        margin-bottom: 40px;
      }
    }

    .bulle_quantity {
      @include font-secondary-medium;
      background-color: transparent;
      color: $white;
      margin-right: 8px;
      padding: 4px 8px;
      font-size: 12px;
      border: 1px solid $white;
      border-radius: 4px;
      transition: 0.25s;
      cursor: pointer;
    }

    .selected_bulle_quantity {
      background-color: $white;
      color: $dark;
    }


    //PRICE and ADD INPUT


    .mito_add_to_cart_button {
      @extend .btn;
      @extend .btn--primary;
      @extend .small;
      position: absolute;
      right: 25px;
      bottom: 25px;
      cursor: pointer;
    }

    .mito_regular_price {
      @include font-secondary-semibold;
      font-size: 24px;

      &.salePrice {
        color: $green;
      }

      &.discountPrice {
        font-size: 14px;
        font-weight: 400;
        text-decoration: line-through;
      }
    } //ends common card
  }
}

//RANDOM ITEMS (MOSTLY GENERATED BY WOOCOMMERCE)

.onsale {
  display: none;
}

.product_meta {
  display: none;
}

.woocommerce-tabs {
  display: inline-block;
}
.woocommerce div.product div.images img {
  height: 50vh !important;
  width: auto !important;
}

.woocommerce-product-gallery__image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.woocommerce div.product {
  min-height: 60vh;
}

.variations_form {
  display: none;
}

.price_promotion_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart {
  display: none;
}
