.quatre-cent-quatre {

  .container {
    width: 90%;
    max-width: 1055px;
    margin-left: auto;
    margin-right: auto;
    min-height:calc(100vh - 185px);
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h1 {
    font-size:10em;
    line-height: 1em;
    margin: 0;
    padding-bottom: 0.3em;
  }
  h2 {
    font-size:2.5em;
  }
}
