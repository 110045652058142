.page-archive-jobs {
  background: $pale;

  .emploi_detail {
    min-height: 500px;
    width: 100vw;
    display: flex;
    hr {
      margin-top: 110px;
      margin-bottom: 110px;
    }
    .emplois_left {
      background-color: $white;
      width: 40%;
    }
    .emplois_right {
      padding: 8%;
      background-color: $pale;
      width: 60%;
      strong {
        color: $green;
        font-size: 22px;
        @include font-secondary-bold;
      }
      h2 {
        @include font-extraBold;
        font-size: 30px;
        color: $dark;
        margin-top: 20px;
      }
      h3 {
        @include font-secondary-bold;
        font-size: 14px;
        color: $gray;
      }
      .description {
        margin-top: 40px;
        @include font-regular;
      }
    }
  }

  .main-banner{
    @include break(mobile){
      display: block;
    }
  }

  .jobs-filter-block {
    position: absolute;
    bottom: 80px;
    left: 0;
    width: 100%;

    @include break(mobile){
      position: static;
    }

    ul.jobs-filter-list {
      display: flex;
      justify-content: center;

      li {
        color: rgba(7, 6, 11, 0.4);
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 14px 16px;
        margin-right: 17px;
        border-radius: 8px;
        transition: 0.3s;

        @include break(mobile){
          font-size: 11px;
          padding: 5px;
          margin-right: 7px;
        }

        &.active {
          color: $green;
          background: $white;
          box-shadow: 0px 4px 8px rgba(7, 6, 11, 0.08);
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  section.block-all-jobs {
    padding: 50px 0px; 

    .container-md {
      .item {
        margin-bottom: 100px;
        display: flex;

        @include break(tablet){
          display: block;
          flex-direction: column;
        }

        @include break(v-mobile){
          margin-bottom: 60px;
        }

        &:last-of-type{
          margin-bottom: 0;
        }

        .category-block {
          max-width: 280px;
          width: 280px;
          margin-right: 50px;

          @include break(tablet){
            margin-bottom: 50px;
          }

          @include break(v-mobile){
            margin-bottom: 35px;
          }
        }

        .jobs-block {
          flex: 1;
        }
      }
    }
  }

  section.spontaneous-candidature {
    padding: 100px 0px;

    @include break(v-mobile){
      padding: 80px 0px;
    }

    .textbox {
      max-width: 560px;
      margin: auto;
      text-align: center;

      @include break(v-mobile){
        max-width: 90%;
      }

      .btn:hover {
        cursor: pointer;
      }


      h2 {
        margin-bottom: 40px;
      }
    }
  }
}

table.table-jobs {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  th.filterActive {
    &:after {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      border-left: 2px solid $dark;
      border-bottom: 2px solid $dark;
      margin-left: 10px;
      transform-origin: center;
      transform: translateY(-3px) rotate(-45deg);
    }
  }

  tr {
    // display: block;
    padding: 40px 0;
    border-bottom: 2px solid black;
    cursor: pointer;

    @include break(v-mobile){
      padding: 20px 0;
    }

    th {
      font-size: 14px;
      padding: 40px 0;
      width: 50%;

      @include break(v-mobile){
        padding: 15px 0;
      }
    }

    td {
      padding: 40px 0;

      @include break(v-mobile){
        padding: 20px 0;
      }
    }

    &:hover {
      .arrow {
        img {
          animation: movearrow 1.2s forwards;
        }
      }
    }

    &:first-of-type {
      padding-top: 0;

      th {
        padding-top: 0;
      }
    }
  }

  .arrow {
    width: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    img {
      transition: 0.4s;
    }
  }
}

@keyframes movearrow {
  0% {
    opacity: 40%;
    transform: translateX(-15px);
  }

  50% {
    opacity: 100%;
  }

  100% {
    opacity: 100%;
    transform: translate(0);
  }
}
