//GENERAL CODE FOR RECIPE AND NOT RECIPE SINGLES
.single-post-template-page{

	.single--content{
		.parent-flex{
			display: flex;

			@include break(tablet){
				flex-direction: column-reverse;
			}
		}
	}

	aside.aside-bar{
		width: 210px;
		margin-right: 40px;

		@include break(tablet){
			display: none;
		}

		.content{
			width: 180px;
			padding-top: 30px;

			.published-on{
				margin-bottom: 28px;
				text-transform: lowercase;

				.title{
					color: rgba(7, 6, 11, .4);
					font-size: 12px;
				}

				.date{
					font-weight: 500;
				}
			}

			.share-block{
				border-top: 1px solid rgba(7, 6, 11, .15);
				padding-top: 28px;

				.title{
					color: rgba(7, 6, 11, .4);
					font-size: 12px;
					margin-bottom: 28px;
				}

				.items{
					li{
						margin-right: 25px !important;
					}
				}
			}
		}
	}

	main.blog-content{
		// max-width: 840px;
		flex: 1;

		.category-name{
			margin-bottom: 18px;

			@include font-secondary-bold;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 800;
			color: rgba(7, 6, 11, 0.4);
		}

		.post-img{
			.img-holder{
				height: 680px;
				border-radius: 8px;

				@include break(tablet){
					height: 500px;
				}

				@include break(mobile){
					height: 400px;
				}

				@include break(v-mobile){
					height: 300px;
				}
			}
		}

		h1{
			max-width: 670px;
			margin-bottom: 80px;

			@include break(tablet){
				margin-bottom: 60px;
			}

		}

		.the-content{
			padding: 50px 0;

			@include break(tablet){
				padding: 60px 0;
			}

			.heateor_sss_sharing_container{
				display: none !important;
			}
		}
	}

	.content-aside-mobile{
		display: none;

		@include break(tablet){
			display: block;
			margin-bottom: 60px;
		}

		p{
			font-size: 12px;
		}

		.title{
			margin-right: 20px;
			opacity: 0.4;
		}

		.published-on{
			display: flex;
			margin-bottom: 20px;
			font-weight: 500;
		}

		.share-block{
			display: flex;
			align-items: center;

			.heateor_sss_sharing_ul{
				li{
					margin-right: 20px !important;
				}
			}
		}
	}

	.prev-next-posts{
		padding: 15px;
		border-bottom: 1px solid rgba(7, 6, 11, .15);
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include break(tablet){
			border-top: 1px solid rgba(7, 6, 11, .15);
		}

		@include break(v-mobile){
			padding: 10px 0;
		}

		.link-archive{
			font-weight: 600;
		}

		.prev-next-wrapper{
			display: flex;
			align-items: center;

			a{
				cursor: pointer;
			}

			.prev-box,
			.next-box{
				padding: 2px 22px;
				display: flex;
				align-items: center;

				@include break(v-mobile){
					padding: 2px 13px;
				}

				.icon-holder{
					padding: 0 17px;
					transition: .3s;

					@include break(v-mobile){
						padding: 0 10px;
						min-width: 30px;
					}
				}

				.txt{
					@include font-secondary-semibold;
					font-size: 14px;

					@include break(v-mobile){
						display: none;
					}
				}
			}

			.prev-box{
				border-right: 1px solid $dark;

				&:hover{
					.icon-holder{
						transform: translateX(-3px);

					}
				}

				&.lastart{
					border: none;
				}
			}

			.next-box{
				padding-right: 0;

				.icon-holder{
					padding-right: 0;
				}

				&:hover{
					.icon-holder{
						transform: translateX(3px);
					}
				}
			}
		}
	}

	section.related-articles{
		padding-top: 80px;
		padding-bottom: 135px;

		.blog-card{
			@include break(v-mobile){
				margin-bottom: 40px;
			}
		}
	}

	.has-wysiwyg{
		ul{
			padding: 15px 0;
			li{
				margin-bottom: 12px;

				&::marker{
					color: $green;
				}
			}
		}
	}
}


//TO BE DECIDED
// SPECIFIC CODE FOR RECIPE SINGLE POSTS
.single-post-template-page.is-recipe{
	main.blog-content{

		.the-content{
			padding: 80px 0 60px;


		}

	}
}

.recipe-intro {
	margin-top: 30px;
	&__title{
		font-weight: 800;
		margin-bottom: 25px;
	}

	&__description{

	}
}

.recipe-summary{
	background: $white;
	border-radius: 8px;
	box-shadow: 0px 4px 8px rgba(7, 6, 11, 0.08);
	padding: 50px 40px 10px;
	margin-top: 30px;

	@include break(v-mobile){
		padding: 15px;
	}

	ul.flex{
		display: flex;
		flex-wrap: wrap;

		li{
			width: calc(100% / 3);
			padding-bottom: 40px;
			padding-right: 15px;

			@include break(mobile){
				width: calc(100% / 2);
			}

			@include break(v-mobile){
				padding-bottom: 20px;
			}

			.title{
				font-size: 12px;
				font-weight: 700;
				margin-bottom: 12px;
				text-transform: uppercase;
			}

			.info{
				font-size: 16px;
			}
		}
	}
}

.block-recipe-step{
	padding: 45px 0;
	border-bottom: 1px solid rgba(7, 6, 11, 0.4);
	border-bottom: 1px solid rgba(7, 6, 11, .15);

	@include break(tablet){
		border: none;
	}

	a{
		color: $green;
		text-decoration: underline;

		&:hover{
			color: $dark-green;
		}
	}

	p {
		margin-bottom: 20px;
	}

	.the-title{
		color: $green;
		margin-bottom: 60px;
	}

	.info{
		.flex{
			display: flex;
			flex-wrap: wrap;

			@include break(mobile){
				display: block;
			}
		}

		.ingredients,
		.preparation{
			width: 50%;

			@include break(mobile){
				width: 100%;
			}

			.title{
				font-weight: 800;
				margin-bottom: 25px;
			}

			ul, ol{
				//padding-left: 12px;
				list-style-position: inside;
				padding-left: 35px;

				li{
					// padding-left: 10px;
					margin-bottom: 8px;
					text-indent: -35px;

					span{
						padding-left: 15px;
					}

					&::marker{
						color: $green;
					}
				}
			}

			ul{
				padding-left: 21px;

				li{
					text-indent: -21px;
				}
			}
		}

		.ingredients{
			padding-right: 30px;

			@include break(mobile){
				margin-bottom: 40px;
			}
		}
	}

	.the-tip{
		background: $dark;
		padding: 20px 30px;
		color: $white;
		display: flex;
		align-items: center;
		margin-top: 62px;
		border-radius: 4px;

		.icon-holder{
			width: 30px;
			margin-right: 25px;

			img{
				@extend .img-resp;
			}
		}

		p{
			font-weight: 600;
		}
	}
}
