.oxo-view.template-home {

	position: relative;
	background: $dark;

	.home_slider {
		position: relative;
		.slider-home {
			height: calc(100vh - 81px);
			width: 100vw;
			position: relative;

			.slider-home_slide {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: none;
				color: #FFFFFF;
				background-position: center;
				background-size: cover;

				video {
					@include absolute-center(center);
					width:100%;
					height:100%;
					object-fit: cover;
					z-index: 0;
				}

				.filter {
					position: absolute;
					background-color: $dark;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 1;
					pointer-events: none;
				}

				.content {
					z-index: 2;
					width: 90%;
					max-width: 535px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					text-align: center;
				}

				.btn{
					margin-top: 7vh;
				}

				&:first-of-type {
					display: block;
				}

				&.align-left {
					.content {
						left: 10%;
						text-align: left;
						transform: translate(0%, -50%);
					}
				}

				&.align-right {
					.content {
						left: auto;
						right: 10%;
						text-align: right;
						transform: translate(0%, -50%);
					}
				}
			}
		}

		.slider_arrow {
			position: absolute;
			z-index: 4;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
			opacity: 0.6;
			transition: all 0.4s;
			@include break(tablet){
				display: none;
			}

			&:hover {
				opacity: 1;
			}

			&-left {
				left: 2.5%;
				transform: translateY(-50%) rotate(180deg);
			}

			&-right {
				right: 2.5%;
			}
		}

		.slider-home_nav {
			position: absolute;
			bottom: 5vh;
			left: 5%;
			right: 5%;
			display: flex;
			justify-content: center;
			z-index: 3;

			.bullet {
				width: 15px;
				height: 15px;
				border-radius: 50%;
				margin-left: 5px;
				margin-right: 5px;
				background-color: $white;
				transition: all 0.4s;
				cursor: pointer;

				&:hover, &.active {
					background-color: $green;
				}

				&.active {
					pointer-events: none;
				}
			}
		}
	}

	.graphic-bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 830px;
		background-size: contain;
		background-repeat: repeat;
	}

	.main-banner{
		color: $white;
		position: relative;

		.main-banner-flex{
			height: 700px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			z-index: 2;
			position: relative;

			@include break(tablet){
				display: block;
				height: auto;
			}

			.text-block,
			.img-block{
				width: calc((100% - 60px) / 2);
			}

			.text-block{
				padding-left: 75px;

				@include break(small-screen){
					padding-left: 0;
				}

				@include break(tablet){
					width: auto;
					padding-top: 80px;
					text-align: center;
					margin-bottom: 50px;
				}

				.text-max-width{
					max-width: 425px;

					@include break(tablet){
						max-width: 625px;
						margin: auto;
					}
				}

				h1{
					margin-bottom: 20px;
				}

				p{
					margin-bottom: 60px;
				}

				.btn-holder{
					display: flex;

					@include break(tablet){
						justify-content: center;
					}

					@include break(v-mobile){
						display: block;
					}

					.btn{
						margin-right: 12px;
						white-space: nowrap;

						@include break(v-mobile){
							display: table;
							margin-bottom: 10px;
							margin-right: 0;
						}

						&.btn--secondary.dark-bg{
							&:hover{
								background: $green;
								opacity: 1;
								color: $white;
								border-color: $green;
							}
						}
					}
				}
			}

			.img-block{

				@include break(tablet){
					width: auto;
				}

				.img-holder{
					height: 600px;
					border-radius: 35%;

					@include break(small-screen){
						margin: auto;
						width: 37vw;
						height: 37vw;
					}

					@include break(mobile){
						width: 300px;
						height: 300px;
					}
				}
			}
		}
	} //section main-banner


	section.img-product-txt{
		padding: 160px 0;


		@include break(tablet){
			padding: 100px 0;
		}

		@include break(mobile){
			padding: 40px 0;
		}

		@include break(v-mobile){
			padding: 20px 0;
		}

		.cards-list{ //ul
			li{
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 60px;

				@include break(mobile){
					display: block;
				}

				.img-side,
				.txt-side{
					width: calc((100% - 35px) / 2);
				}

				.txt-side{
					padding: 0 0 0 130px;
					color: $white;

					@include break(tablet){
						padding: 0 0 0 20px;
					}

					@include break(mobile){
						width: auto;
						max-width: 450px;
						text-align: center;
						padding-left: 0;
						margin-left: auto;
						margin-right: auto;
					}

					h2, .title{
						margin-bottom: 25px;
					}

					p{
						margin-bottom: 45px;

						@include break(mobile){
							margin-bottom: 28px;
						}
					}
				}

				.img-side{
					height: 480px;
					position: relative;
					border-radius: 35%;

					@include break(tablet){
						height: 20vw;
						height: 39vw;
						max-width: 39vw;
					}


					@include break(mobile){
						margin: 0 auto 40px;
					}

					.sushi-img-holder{
						max-width: 247px;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);

						@include break(tablet){
							
						}

						img{
							max-width: 100%;
							height: auto;
							display: block;
						}
					}
				}

				&:nth-of-type(even){
					.img-side{
						order: 2;

						@include break(mobile){
							order: 1;
						}
					}

					.txt-side{
						order: 1;
						padding: 0 130px 0 0;

						@include break(mobile){
							order: 2;
						}

						@include break(tablet){
							padding: 0;
						}
					}
				}
			}
		}
	} //.img-product-txt

	section.slider-chefs{
		padding: 160px 0;

		@include break(tablet){
			padding: 80px 0;
			min-height: 1240px;
		}

		@include break(mobile){
			padding: 40px 0;
			min-height: 1000px;
		}

		.section-title{
			color: $white;
			border-left: 1px solid $green;
			padding-left: 35px;
			max-width: 750px;
			margin-bottom: 90px;
		}

		.slider_swipe {
			display: flex;
			margin-top: 150px;
			justify-content: space-between;
			position: relative;
			
			@include break(tablet) {
				flex-direction: column;
				align-items: center;
				height: 500px;
			}

			.slider_left {
				width: 45%;
				padding-bottom: 53%;
				position: relative;
				cursor: grab;
				

				&:active {
					cursor: grabbing;
				}

				.slider_arrow_wrapper{
					cursor: pointer;
					z-index: 99;
					width: 69px;
					height: 64px;
					position: absolute;
					@include absolute-center(center);
				}

				.slider_arrow {
					position: absolute;
					@include absolute-center(center);
					z-index: 9;
					opacity: 0;
					transition: all 0.4s;
					pointer-events: none;
					// cursor: pointer !important;
				}

				.slide_img {
					background-position: center;
					background-size: cover;
					height: 560px;
					width: 400px;
					position: absolute;
					@include absolute-center(center);
					opacity: 0;
					pointer-events: none;

					@include break(mobile){
						height: 500px;
						width: 350px;
					}

					@include break(v-mobile){
						height: 330px;
						width: 236px;
					}

					&:nth-of-type(1) {
						opacity: 1;
					}

					&:nth-of-type(2) {
						transform: translate(-50%,-50%) rotate(10deg);
						opacity: 1;
					}
					&:nth-of-type(3) {
						opacity: 1;
						transform: translate(-50%,-50%) rotate(-10deg);
					}
				}
			}
			.slider_right {
				padding-top: 5%;
				padding-bottom: 5%;
				width: 40%;
				color: $white;

				@include break(tablet){
					position: absolute;
					top: calc(100% + 100px);
					padding: 0;
					width: 400px;
				}

				@include break(mobile){
					top: calc(100% + 50px);
				}

				@include break(v-mobile){
					top: auto;
					bottom: 50px;
					width: 100%;
					max-width: 400px;
				}

				.num {
					@include font-secondary-semibold;

					@include break(tablet){
						display: flex;
						justify-content: space-between;
						padding-bottom: 30px;
					}

					.txt {
						display: inline-block;
						width: 12px;
						text-align: center;
						color: $green;
						font-size: 24px;
					}

					.arrows-mobile{
						display: none;
						width: 65px;

						@include break(tablet){
							display: flex;
							justify-content: space-between;
						}

						.arrow-left,
						.arrow-right{
							cursor: pointer;
						}
					}
				}

				.slider_contentWrap {
					height: 100%;
					position: relative;
				}

				.slide_content {
					width: 100%;
					position: absolute;
					@include absolute-center(center);
					opacity: 0;

					@include break(tablet){
						// position: static;
						top: 0;
						left: 0;
						transform: translate(0) !important;
					}

					h3 {
						margin-bottom: 25px;
					}

					.btn {
						margin-top: 40px;
					}

					&:first-of-type {
						opacity: 1;
					}
				}
			}

			&:hover .slider_arrow {
				opacity: 1;
			}
		}
	}


	.video-block {
		padding: 160px 0;


		@include break(tablet){
			padding: 80px 0;
		}

		@include break(mobile){
			padding: 40px 0;
		}

		color: #FFFFFF;
		.container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@include break(tablet){
				flex-direction: column;
			}

			.left {
				width: 30%;
				@include break(tablet){
					width: 100%;
					padding-bottom: 60px;
				}
				h3 {
					margin-bottom: 25px;
				}

				.btn {
					margin-top: 45px;
				}
			}

			.right {
				width: 60%;
				@include break(tablet){
					width: 100%;
				}

				.img {
					width: 100%;
					position: relative;
					background-position: center;
					background-size: cover;
					padding-bottom: 56%;

					svg {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						transition: all 0.4s;
						cursor: pointer;
					}

					&:hover svg{
						transform: translate(-50%, -50%) scale(1.1);
					}
				}
			}
		}
	}

	.popup-yt {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: none;
		z-index: 999999;
		align-items: center;
		justify-content: center;

		.inner {
			position: absolute;
			width: 80%;
			height: 80vh;
			transform-origin: center;
			display: flex;
			align-items: center;
			@include absolute-center(center);
			z-index: 2;
			pointer-events: none;
		}

		.video_wrap {
			pointer-events: all;
			width: 100%;
			position: relative;
			padding-bottom: 56.25%; /* 16:9 */
			height: 0;

			iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			}
		}

		.close {
			position: absolute;
			top: 20px;
			right: 20px;
			padding: 10px;
			cursor: pointer;
			z-index: 2;
		}

		.bg {
			background: rgba(0, 0, 0, 0.7);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
		}

	}


	section.img-txt-block.img-vertical{
		h2{
			max-width: 296px;
		}
	}

}
