.single-comptoir {

			a {
				color: $green;
				&:hover {
					text-decoration: underline;
				}
			}

			.info_section_header{
				padding-bottom: 20px;
				margin-bottom: 40px;
				border-bottom: 1px solid rgba($dark,0.15);

				h2, .store-name{
					margin-bottom: 12px;
				}
			}

			a.item-detail.address {
				color: $green;
			}

			.store-info-block{
				margin-bottom: 40px;

				.item-title{
					@include font-secondary-regular;
					font-size: 12px;
					text-transform: uppercase;
					letter-spacing: 0.1em;
					margin-bottom: 8px;
				}
			}

			.store-timetable{
				display: flex;
				justify-content: space-between;
				@include break(tablet) {
					flex-direction: column;
				}
				.left {
					width: 255px;
					flex-shrink: 0;
				}
				.right {
					width: 100%;
					padding-left: 20%;
					flex-shrink: 1;
					@include break(tablet) {
						padding-left: 0%;
						margin-top: 40px;
					}
					#map {
						width: 100%;
						padding-bottom: 60%;
					}
				}

				.time-detail-row{
					display: flex;
					justify-content: space-between;
					margin-bottom: 7px;
				}
			}

		.info_text{
			padding-top: 20px;
			margin-bottom: 80px;
			border-top: 1px solid rgba($dark,0.15);
		}

}
