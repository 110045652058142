.page-faq{
	background: $pale;

	.faq-block{
		margin-bottom: 80px;

		@include break(tablet){
			margin-bottom: 40px;
		}

		@include break(v-mobile){
			margin-bottom: 40px;
		}

		h2{
			margin-bottom: 40px;
		}

		ul.faq-list{
			list-style: none;

			& > li{
				margin-bottom: 24px;
				background: $white;
				border-radius: 8px;
				transition: .3s;
				cursor: pointer;

				@include break(v-mobile){
					 margin-bottom: 14px;
				}

				&:hover{
					box-shadow: 0px 4px 8px rgba(7, 6, 11, 0.04);
				}

				&.active{
					box-shadow: 0px 4px 8px rgba(7, 6, 11, 0.04);

					.question{
						color: $green;

						.icon-holder{
							transform: translateY(-50%) rotate(45deg);
						}
					}
				}
			}

			.question{
				min-height: 105px;
				padding: 20px 180px 20px 40px;
				display: flex;
				align-items: center;
				position: relative;

				@include break(tablet){
					padding: 20px 100px 20px 40px;
					min-height: 90px;
				}

				@include break(mobile){
					padding: 20px 60px 20px 20px;
					min-height: 80px;
				}

				span.icon-holder{
					width: 28px;
					height: 28px;
					position: absolute;
					top: 50%;
					right: 40px;
					transform: translateY(-50%) rotate(0deg);
					display: block;
					transition: .3s;

					@include break(tablet){
						width: 24px;
						height: 24px;
						right: 30px;
					}

					@include break(v-mobile){
						width: 18px;
						height: 18px;
						right: 20px;
					}

					&:before,
					&:after{
						content: '';
						background: $dark;
						height: 4px;
						width: 100%;
						position: absolute;
						left: 0;
						top: 50%;
						display: block;
						
					}

					&:before{
						transform: translateY(-50%) rotate(90deg);
					}

					&:after{
						transform: translateY(-50%);
					}
				}
			}

			.answer{
				padding: 10px 80px 40px 40px;
				display: none;

				@include break(tablet){
					padding: 10px 35px 10px;
				}

				@include break(mobile){
					padding: 10px 20px 20px;
				}

				a{
					color: $green;
					text-decoration: underline;
				}

				p{
					margin-bottom: 20px;

					&:last-of-type{
						margin-bottom: 0;
					}
				}

				ul, ol{
					padding: 10px 0;

					li{
						margin-bottom: 8px;
					}
				}
			}
		}
	}
}














