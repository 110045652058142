.page-b2b{
	background-color: $pale;
	
	.main-banner{
		height: calc(100vh - 80px);
		position: relative;
		display: flex;
		align-items: center;
		overflow-x: hidden;

		@include break(small-screen){
			display: block;
			height: auto;
		}

		.breadcrumb-theme{
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}

		.container-md{
			@include break(small-screen){
				padding: 100px 0;
			}
		}

		.textbox{
			max-width: 510px;

			@include break(small-screen){
				text-align: center;
				margin-left: auto;
				margin-right: auto;
			}

			h1{
				max-width: 400px;
			}

			p{
				margin-bottom: 60px; 
			}
		}

		.img-side{
			width: 50vw;
			height: 100%;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(100px);

			@include break(small-screen){
				position: static;
				height: 50vw;
				width: 100%;
				transform: translateX(0);
				background-position: bottom center;
			}
		}
	}

	section.our-mission{
		padding: 200px 0;
		padding: 160px 0; //v1 while no bg

		background-size: contain; //to be confirmed
		background-repeat: no-repeat;

		background-image: none !important; //for v1 remove bg

		@include break(tablet){
			padding: 100px 0;
		}

		@include break(v-mobile){
			padding: 80px 0;
		}

		.textbox{
			width: 100%;
			background: $white;
			min-height: 290px;
			border-radius: 8px;

			p{
				max-width: 460px;
			}
		}

		//page B2B textbox second 2
		&.institutions{

			.textbox{
				padding: 80px 107px;

				@include break(tablet){
					padding: 60px 50px;
				}

				@include break(v-mobile){
					padding: 40px 22px;
				}

				p{
					max-width: none;
					margin-bottom: 0;
				}
			}

			.logos-holder{
				padding-top: 40px;

				ul.logos-list{
					display: flex;
					justify-content: space-around;
					align-items: center;
					flex-wrap: wrap;

					li{
						width: calc((100% - 140px) / 4);

						@include break(mobile){
							width: calc((100% - 70px) / 2);
							margin-bottom: 20px;
						}

						img{
							max-width: 100%;
							height: auto;
							max-height: 77px;
							margin: auto;
						}
					}
				}
			}
		}
	}

	section.certifications{
		padding: 90px 0;
		padding-bottom: 190px;
		max-width: 840px;
		margin-left: auto;
		margin-right: auto;

		@include break(v-mobile){
			padding: 70px 0;
		}

		.section-header{
			
			margin-bottom: 40px;
			text-align: center;

			h2{
				margin-bottom: 20px;
			}
		}

		.section-content{
			flex: 1;
			padding: 20px 0;

			.flex{
				display: flex;
				justify-content: center;
				flex-wrap: wrap;

				.item{
					margin-bottom: 40px;
					width: calc(100% / 3);

					@include break(mobile){
						width: 50%;
						margin-bottom: 30px;
					}

					.card{
						max-width: 180px;
						margin: auto;
						text-align: center;

						@include break(mobile){
							max-width: 150px;
						}

						.icon-holder{
							position: relative;

							.icon-bg{
								max-width: 185px;

								@include break(mobile){
									max-width: 150px;
								}
							}

							.icon{
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);

								@include break(mobile){
									max-width: 100px;
								}
							}

							img{
								max-width: 100%;
								height: auto;
								display: block;
								margin: auto;
							}
						}

						.info{
							padding: 20px 5px;

							p{
								max-width: 190px;
								margin: auto;
							}
						}
					}
				}
			}

			.btn-holder{
				text-align: center;
			}
		}
	}//section Certifications
}







