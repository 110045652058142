.dealer-map {
  height: calc(100vh - 80px);
}
.delivery-list {
  display: flex;
  width: 100%;
  align-items: center;
  a:last-child {
    margin-left: 20px;
  }
}
.section-stores {
  background: $pale;

  .container {
    @include break(v-mobile) {
      width: 100%;
    }
  }

  .wrapper-fluid {
    display: flex;

    height: calc(100vh - 80px);
    flex-wrap: nowrap;

    position: relative;
  }

  .breadcrumb-theme {
    position: relative;
    z-index: 1;

    @include break(v-mobile) {
      padding: 0;
    }

    .container {
      width: 100%;
      padding-right: 20px;

      @include break(v-mobile) {
        padding: 20px;
      }
    }
  }

  .div-scroll {
    // height: 700px;
    // overflow-y: scroll;
    height: 100% !important;
    overflow-y: scroll !important;
    width: 100%;
    display: block !important;

    @include break(v-mobile) {
      padding: 20px;
    }
  }

  //LEFT SIDE (SEARCH FORM)
  .wrapper-fluid__left {
    width: 440px;
    display: flex;
    flex-direction: column;

    @include break(v-mobile) {
      width: 100%;
    }

    .cards-holder {
      flex: 1;
    }
  }

  .header-input-block {
    background-color: $white;
    padding: 20px 45px 40px 0;
    position: relative;

    @include break(v-mobile) {
      padding: 20px;
    }

    & > * {
      position: relative;
    }

    &:before {
      content: "";
      background: $white;
      position: absolute;
      right: 0;
      bottom: 0;
      height: calc(100% + 58px);
      width: 80vw;
      min-width: 600px;
    }

    h5 {
      margin-bottom: 24px;
    }

    .search-form {
      position: relative;

      input[type="text"] {
        padding: 15px 60px;
        border: 1px solid rgba(7, 6, 11, 0.15);
        border-radius: 4px;
        width: 100%;
        @include font-secondary-regular;
        font-size: 14px;
      }

      .search-icon-holder,
      .current-position-holder {
        position: absolute;
      }

      button.current-position-holder {
        width: 43px;
        height: 43px;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        border-right: 1px solid rgba(7, 6, 11, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        cursor: pointer;

        svg {
          pointer-events: none;
        }
      }

      .search-icon-holder {
        width: 46px;
        height: 46px;
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;

        input.search-btn {
          background-color: transparent;
          width: 100%;
          height: 100%;
          position: absolute;
          font-size: 0px;
          border: none;
          z-index: 5;
        }

        svg {
          position: relative;
          stroke: rgba(7, 6, 11, 0.4);
          pointer-events: none;
        }
      } //.search-icon-holder
    } //.search-form
  } //.header-input-block

  .cards-holder {
    padding-right: 30px;
    padding-top: 40px;

    //card
    .dealer {
      border: 1px solid rgba(7, 6, 11, 0.15);
      color: $dark;
      padding: 22px;
      border-radius: 4px;
      margin-bottom: 20px;
      transition: 0.3s;
      cursor: pointer;
      transform: translateX(1px);

      &.active,
      &:hover {
        border-color: $green;
        box-shadow: 0px 0px 0px 1px $green;
      }

      h6 {
        @include font-secondary-semibold;
        margin-bottom: 12px;
      }

      p {
        font-size: 14px;

        .address {
          margin-bottom: 8px;
        }
      }

      .btn-voir-fiche {
        margin-top: 29px;
        border: none;
      }
    }
  }

  // INFO STORE
  .info_place {
    color: white;
    background-color: $dark;
    width: 430px;
    transition: 0.3s;
    position: relative;
    z-index: 995;
    // padding-top: 30px;
    // padding-right: 80px;
    // padding-left: 1px;

    &:before {
      content: "";
      height: 100%;
      width: 40vw;
      background: $dark;
      position: absolute;
      right: calc(100% - 1px);
      top: 0;
    }

    &:after {
      content: "";
      height: 1px;
      width: 100%;
      background: white;
      position: absolute;
      right: 0;
      bottom: 0;
      opacity: 15%;
    }

    .scroll-div {
      height: 100% !important;
      overflow-y: scroll !important;
      padding-top: 30px;
      padding-right: 80px;
      padding-left: 1px;

      @include break(v-mobile) {
        padding: 30px 20px;
      }
    }

    .btn-return {
      @include font-secondary-semibold;
      background: transparent;
      color: $green;
      font-size: 12px;
      padding-left: 28px;
      position: relative;
      border: none;
      margin-bottom: 50px;

      span.icon-holder {
        width: 17px;
        height: 17px;
        border: 1px solid $green;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        &:before {
          content: "";
          width: 5px;
          height: 5px;
          border-left: 1px solid $green;
          border-bottom: 1px solid $green;
          transform-origin: center;
          transform: translateX(1px) rotate(45deg);
        }
      }
    } //btn-return

    .info_section_header {
      padding-bottom: 20px;
      margin-bottom: 40px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);

      h2,
      .store-name {
        margin-bottom: 12px;
      }
    }

    .store-description a {
      color: $green;
    }

    .store-info-block {
      margin-bottom: 40px;

      .item-title {
        @include font-secondary-regular;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-bottom: 8px;
      }
    }

    .store-timetable {
      width: 255px;

      .time-detail-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 7px;
      }
    }
  } //.info-place
}

::placeholder {
  @include font-secondary-regular;
  font-size: 14px;
  color: rgba(7, 6, 11, 0.4);
}

.wrapper-fluid__right {
  position: absolute;

  left: 442px;
  // top: 0;
  width: calc((100% - 442px) + ((100vw - 1240px) / 2));
  height: 100%;

  @media only screen and (max-width: 1376px) {
    width: calc((100% - 442px) + (10vw / 2));
  }

  @include break(v-mobile) {
    display: none;
  }
}

.gm-svpc {
  display: none !important;
}
