.page-franchise{
	background: $pale;

	//SECTION MAIN IMAGE
	.main-image{
		height: 670px;
		background-position: center;

		@include break(tablet){
			height: 600px;
		}

		@include break(mobile){
			height: 47vw;
		}
	}

	//SECTION FRANCHISE DATA{
	section.franchise-data{
		padding: 150px 0;

		@include break(tablet){
			padding: 100px 0;
		}

		@include break(tablet){
			padding-bottom: 60px;
		}

		@include break(v-mobile){
			padding: 60px 0 0;
		}

		.section-title{
			margin-bottom: 60px;
			text-align: center;
		}

		.flex-parent{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.card{
				width: calc((100% - 90px) / 4);
				text-align: center;

				@include break(mobile){
					width: calc((100% - 25px) / 2);
					margin-bottom: 35px;
				}

				.icon-holder{
					position: relative;

					.icon-bg{
						max-width: 185px;
					}

					.icon{
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}

					img{
						max-width: 100%;
						height: auto;
						display: block;
						margin: auto;

						@include break(mobile){
							width: 65%;
						}
					}
				}

				.info{
					padding: 20px 5px;

					p{
						max-width: 190px;
						margin: auto;
					}
				}
			} //.card
		}

	}//section Franchise data (comptoir en epicerie)


	section.banner-data-market{
		background-color: $dark;
		padding: 160px 0 100px 0;

		@include break(small-screen){
			padding-left: 80px;
			padding-right: 80px;
		}

		@include break(tablet){
			padding: 80px 0;
		}

		& > *{
			color: $white;
		}

		.flex-parent{
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			flex-wrap: wrap;

			@include break(tablet){
				display: block;
			}

			.section-title{
				max-width: 260px;
				margin-right: 200px;
				margin-bottom: 60px;

				@include break(lg){
					margin-right: 140px;
				}

			}

			.data-block-holder{
				display: flex;
				justify-content: space-between;
				max-width: 590px;
				flex: 1;

				@include break(v-mobile){
					display: block;
				}

				.info-box{
					max-width: 240px;
					min-width: 230px;

					@include break(lg){
						min-width: 0;
						width: calc((100% - 80px) / 2)
					}

					@include break(tablet){
						min-width: 0;
						width: calc((100% - 40px) / 2);
					}

					@include break(v-mobile){
						width: 100%;
					}

					&:first-of-type{
						margin-right: 200px;

						@include break(lg){
							margin-right: 0;
						}

						@include break(v-mobile){
							margin-bottom: 30px;
						}
					}

					h4{
						color: $green;
						font-weight: 600;
						margin-bottom: 8px;
					}
				}
			}
		}
	}
}
