.page-my-account, .page-checkout.not-login{
	overflow-x: hidden;

	.breadcrumb-theme{
		.container{
			width: 100%;
			margin-bottom: 80px;
		}
	}

	.entry-content{
		position: relative;
	}

	.container{
		.left-side{
			position: relative;

			@include break(tablet){
				width: 40%;
			}

			@include break(mobile){
				display: none;
			}

			.img-holder{
				height: 100%;
				background-image: url(../img/bg_my_account.jpg);
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				width: 50vw;
				position: absolute;
				top: 0;
				right: calc(50% + 20px);
				right: 0;
			}
		}
	}

	.account_section{
		display: flex;

		.left-side,
		.right-side{
			width: 50%;
			min-height: calc(100vh - 80px);
		}



		.right-side{
			padding: 90px;
			position: relative;
			display: flex;
			align-items: center;

			@include break(small-screen){
				padding-right: 60px;
				padding-left: 60px;
			}

			@include break(tablet){
				width: 60%;
				padding-right: 20px;
			}

			@include break(mobile){
				width: 100%;
				padding-left: 0;
				padding-right: 0;
			}

			.form-login-wrapper{
				max-width: 475px;
			}

			.subscribe-template{
				width: 100%;
			}

			h2{
				margin-bottom: 30px;
			}
		}
	}

	.full-width{
		width: 100% !important;
		float: none !important;
	}

	.woocommerce-privacy-policy-text{
		display: none;
	}

	.woocommerce-form{
		border: none !important;
		padding: 0 !important;

		.form-row{
			.required{
				color: $dark;
			}
		}
	}

	.lost_password{
		@include font-semiBold;
		text-align: right;
		font-size: 10px;
		text-decoration: underline;
		margin-bottom: 15px;
	}

	.woocommerce #respond input#submit,
	.woocommerce a.button,
	.woocommerce button.button,
	.woocommerce input.button{
		@extend .btn;
	}

	.register-link{
		position: absolute;
		bottom: 40px;
		left: 50%;
		transform: translateX(-50%);
		white-space: nowrap;

		a{
			color: $green;
			font-weight: 600;

			@include break(tablet){
				display: block;
				text-align: center;
			}

			&:hover{
				color: $dark-green;
			}
		}
	}

	footer{
		// display: none;
	}

	table.woocommerce-orders-table{
		border-color: none !important;

	}

} //.page-my-account

//DETAILS DE LA COMMANDE
.woocommerce-view-order{

	.resume-commande{
		margin-bottom: 40px;
	}

	h2{
		margin-bottom: 20px;
	}
}


.subscribe-template{

	.txt-link{
		padding: 0 !important;
		color: $green !important;
		background: none !important;
		font-weight: 600 !important;

		&:hover{
			color: $dark-green;
		}
	}

	.phone-wrapper{
		max-width: 219px;
		position: relative;
	}

	.g-recaptcha-register-i13 {
		margin-bottom: 20px;
	}

	.connect-here{
		text-align: center;
		margin-top: 50px;
	}
}

.page-my-account{
	.woocommerce{
		nav.woocommerce-MyAccount-navigation{
			// border: 3px solid blue;
			padding-right: 40px;

			@include break(tablet){
				width: 45%;
			}

			@include break(mobile){
				margin-bottom: 80px;
				width: 100%;
				max-width: 370px;
				padding-right: 0;
			}

			.welcome{
				padding-bottom: 18px;
				border-bottom: 1px solid $dark;
				margin-bottom: 24px;

				p{
					margin-bottom: 8px;
				}

				h4{
					@include font-secondary-semibold;
				}
			}

			ul.dashboard-items-list{
				list-style: none;
				display: flex;
				flex-direction: column;

				li{
					margin-bottom: 8px;
					position: relative;

					a{
						@include font-secondary-regular;
						width: 100%;
						padding: 10px 43px;
						border-radius: 4px;
						// position: relative;
						font-size: 14px;
						display: block;
						background: rgba(7, 6, 11, 0.05);
						transition: .3s;

						&:hover{
							background: rgba(7, 6, 11, 0.1);
						}

					}

					&.is-active{
						a{
							background: $green;
							color: $white;
						}

						&:after{
							border-color: white;
						}
					}

					&:after{ //arrow
						content: '';
						border-right: 1px solid $dark;
						border-bottom: 1px solid $dark;
						width: 5px;
						height: 5px;
						position: absolute;
						top: 50%;
						right: 17px;
						transform-origin: center;
						transform: translateY(-50%) rotate(-45deg);
					}
				}

				.woocommerce-MyAccount-navigation-link--orders{
					&:before{
						content: url(../img/icon_basket_dark.svg);
						position: absolute;
						left: 0px;
						top: 0px;
						display: block;
						transition: .3s;
					}

					&.is-active{
						&:before{
							content: url(../img/icon_basket_white.svg);
						}
					}
				}

				.woocommerce-MyAccount-navigation-link--edit-account{
					order: -1;

					&:before{
						content: url(../img/icon_profile_dark.svg);
						// width: 20px;
						// height: 20px;
						position: absolute;
						left: 0px;
						top: 0px;
						display: block;
					}

					&.is-active{
						&:before{
							content: url(../img/icon_profile_white.svg);
						}
					}
				}

				.woocommerce-MyAccount-navigation-link--customer-logout{
					background: transparent;
					text-align: center;
					color: $green;
					margin-top: 20px;

					@include break(mobile){
						text-align: left;
					}

					@include break(v-mobile){
						text-align: center;
					}

					a{
						background: transparent;
						font-weight: 600;
						padding: 0;

						&:hover{
							background: inherit;
							color: inherit;
						}
					}

					&:before,
					&:after{
						display: none;
					}
				}
			}
		}

		div.woocommerce-MyAccount-content{
			// border: 3px solid red;
			min-height: 600px;
			border-left: 1px solid $black;
			padding-left: 120px;
			margin-bottom: 80px;

			@include break(tablet){
				width: 55%;
				padding-left: 40px;
			}

			@include break(mobile){
				border: none;
				padding-left: 0;
				width: 100%;
			}

			a {
				text-decoration: underline;
			}
		}
	}
}

.woocommerce-info{
	@include font-secondary-regular;
	border-top-color: #80C537 !important;

	&:before{
		color: #80C537 !important;
	}
}

.woocommerce-form-row--first,
.woocommerce-form-row--last{
	@include break(v-mobile){
		width: 100% !important;
		float: none !important;
	}
}

.dashboard-account-details{
	h2{
		margin-bottom: 60px;
	}

	.name-display-msg{
		font-size: 12px;
		margin-top: 5px;
		font-style: normal;

		em{
			font-style: normal;
		}
	}

	.change-pw{
		hr{
			margin: 40px 0;
			height: 1px;
        	background-color: rgba(7, 6, 11, 0.05);
       	    border: none;
		}
	}

	.woocommerce-Button{
		margin-top: 60px !important;
		width: 100%;

		&:hover{
			background: $dark-green !important;
			border-color: $dark-green !important;
		}
	}
}

.woocommerce-edit-account{
	.woocommerce{
		padding-bottom: 80px;
	}
}

.mes-commandes{
	h2{
		margin-bottom: 40px;
	}

	table{
		width: 100%;
		border-collapse: collapse;
		margin-bottom: 40px;
		border: none !important;

		tr{
			padding: 40px 0;
			border-bottom: 2px solid rgba(7, 6, 11, 0.05);

			th{
				color: rgba(7, 6, 11, 0.4);
				font-size: 10px;
				text-transform: uppercase;
				text-align: left !important;
				padding-bottom: 10px;
			}
		}

		td{
			padding: 35px 0;
		}
	}
}

.woocommerce-lost-password{
	.page-my-account{
		.woocommerce{
			min-height: 60vh;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			form{
				max-width: 600px;
				text-align: center;

				.woocommerce-form-row{
					margin: 40px auto;
					float: none;

					input{
						padding: 6px 10px;
					}
				}
			}
		}
	}
}


.page-checkout.not-login {
	 min-height: 0px;
	 .form-login-wrapper h2 {
	 	display: none;
	 }
}
