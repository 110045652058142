//PAGE ARCHIVE - HOME-ACCUEIL
//PAGE ARCHIVE - EACH CATEGORY
//1. GENERAL (GLOBAL) ELEMENTS
//2. SPECIFIC ELEMENTS


//1. GENERAL (GLOBAL) ELEMENTS
.articles-block{
	padding-top: 30px;
	border-top: 1px solid $dark;
	margin-bottom: 75px;

	@include break(mobile){
		padding-top: 50px;
		margin-bottom: 50px;
	}

	.articles-block-header{
		margin-bottom: 40px;
		display: flex;
		justify-content: space-between;

		a.btn{
			@include break(v-mobile){
				display: none;
			}
		}
	}
}

//BLOG CARDS (REUSABLE MULTIPLE PAGES)
.blog--cards{
	list-style: none;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;


	&.col-2{
		li{
			width: calc((100% - 36px) / 2);

			@include break(mobile){
				width: calc((100% - 20px) / 2);
			}

			@include break(v-mobile){
				width: 100%;
			}

			&:nth-of-type(3){
				display: none;
			}

			.img-holder{
				height: 340px;

				@include break(tablet){
					height: 220px;
				}
			}
		}
	}

	&.col-3{
		li{
			width: calc((100% - 72px) / 3);

			@include break(tablet){
				width: calc((100% - 36px) / 2);
			}

			@include break(mobile){
				width: calc((100% - 20px) / 2);
			}

			@include break(v-mobile){
				width: 100%;
			}

			.img-holder{
				height: 220px;
			}
		}

		.blog-card-phantom{
			height: 0;
			// width: calc((100% - 72px) / 3);
			margin-bottom: 0!important;
		}
	}

	.blog-card{
		position: relative;
		padding-bottom: 40px;
		height: 100%;

		.img-holder{
			border-radius: 8px;
		}

		.link-overlay{
			&:before{
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		&:hover{
			.post-link:before{
				right: -3px;
			}
		}

		.info-holder{
			padding-top: 22px;

			.post-categories{
				margin-bottom: 12px;

				@include font-secondary-bold;
				text-transform: uppercase;
				font-size: 10px;
				font-weight: 800;
				color: rgba(7, 6, 11, 0.4);
				li {
					display: inline;
					&:after {
						content: ", ";
					}
					&:last-child {
						&:after {
							content: "";
						}
					}
				}
			}

			.post-excerpt{
				padding: 15px 0;
				font-size: 14px;
			}

			.post-link{
				color: $green;
				position: absolute;
				bottom: 0;
				left: 0;
				padding-right: 25px;
				font-weight: 600;

				&:before{
					content: url(../img/arrow_green_right.svg);
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					transition: .3s ease-in-out;
				}

				&:hover{
					&:before{
						right: -3px;
					}
				}
			}
		}
	}
}


//2.SPECIFIC ELEMENTS
.page-home-archive-blog{
	.blog-home-top{
		padding-top: 50px;

		@include break(v-mobile){
			padding-top: 40px;
		}

		.articles-block{
			border: none;
		}

		.section-title{
			margin-bottom: 20px;
		}
	}

	.articles-block{
		.blog--cards{
			&.col-2{
				li{

					&:nth-of-type(2){
						@include break(v-mobile){
							display: none !important;
						}
					}
				}
			}

			&.col-3{
				li{

					&:nth-of-type(3){
						@include break(tablet){
							display: none !important;
						}
					}

					&:nth-of-type(2){
						@include break(v-mobile){
							display: none !important;
						}
					}
				}
			}
		}
	}

	.btn-mobile-holder{
		margin-top: 40px;
		text-align: center;
		display: none;

		@include break(v-mobile){
			display: block;
		}
	}
}

.blog-home-complete-list{
	padding-bottom: 80px;

	@include break(mobile){
		padding-bottom: 40px;
	}
}

.page-archive-blog-categories{

	section.blog-archive-main{
		padding: 40px 0;
	}

	.articles-block{
		border: none;
		margin-bottom: 0;
	}

	.blog--cards{

		&.col-3{
			li{
				margin-bottom: 75px;

				@include break(mobile){
					margin-bottom: 45px;
				}
			}
		}
	}

	.btn-holder{
		padding-top: 50px;
		text-align: center;

		.btn {
			cursor: pointer;
		}
	}

	.noResult {
		display: none;
	}

	.filters_wrap {
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		@include break(mobile){
			flex-direction: column;
			width: 100%;
		}
		.reset {
			cursor: pointer;
			font-weight: 600;
			font-size: 12px;
			line-height: 15px;
			text-decoration-line: underline;
			display: none;
			align-items: center;
			svg {
				margin-right: 5px;
			}

			&.active {
				display: flex;
			}
		}
	}

	.has-form{
		display: flex;
		@include break(mobile){
			flex-direction: column;
			width: 100%;
		}

		.filters {
			margin-right: 20px;
			position: relative;
			&:hover .drop{
				border: 1px solid $green;
			}

			.selected {
				display: block;
				padding: 10px 40px 10px 20px;
				color: $dark;
				position: relative;
				cursor: pointer;
				min-width: 270px;
				@include break(mobile){
					width: 100%!important;
					margin-bottom: -44px;
					min-width: auto;
				}

				svg {
					position: absolute;
					top: 20px;
					right: 20px;
				}
			}

			.drop {
				border: 1px solid rgba(7, 6, 11, 0.2);
				border-radius: 4px;
				height: 44px;
				overflow: hidden;
				position: absolute;
				top: 0px;
				padding-top: 44px;
				left: 0;
				right: 0;
				z-index: 9999;
				transition: border 0.4s;
				pointer-events: none;
				@include break(mobile){
					position: relative;
				}
				.option_wrap {
					overflow-y: scroll;
					overflow-x: hidden;
					background-color: $white;
					border-top: 1px solid $green;
					height: 188px;
					pointer-events: all;
				}

				.option {
					white-space: nowrap;
					padding: 10px 20px 10px 20px;
					transition: all 0.4s;
					pointer-events: all;
					cursor: pointer;

					&:hover, &.active {
						background-color: rgba(7, 6, 11, 0.05);
					}
				}
			}
		}
	}
}

.single-post-template-page{
	.single--content{
		padding-top: 50px;
	}
}
