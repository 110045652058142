/*  --- Variable ---  */

//FONT

@mixin font-regular {
  font-family: "Archivo SemiExpanded", sans-serif;
  font-weight: 400;
}
@mixin font-semiBold {
  font-family: "Archivo SemiExpanded", sans-serif;
  font-weight: 600;
}

@mixin font-bold {
  font-family: "Archivo SemiExpanded", sans-serif;
  font-weight: 700;
}
@mixin font-extraBold {
  font-family: "Archivo SemiExpanded", sans-serif;
  font-weight: 800;
}

@mixin font-secondary-regular {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

@mixin font-secondary-medium {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

@mixin font-secondary-semibold {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

@mixin font-secondary-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

/* --- Variables for fonction --- */

$font-size: 16px;

//COLOR

$dark: #07060b;
$btn-sec-on-light-hover: #4f4f53;
$btn-sec-on-dark-hover: #b5b4b6;
$pale: #f8f9fb;
$green: #80c537;
$dark-green: #74ab3a;
$red: #b00c2f;
$orange: #f15025;

$black: #000000;
$white: #ffffff;
$beige: #b2af9f;
$gray: #a8a8ab;
$red: #99321e;
$blue: #265778;
$grey: #f0f0f0;
