
.page-choice-home{
	.main-section{
		position: relative;
		background: $dark;

		.parent-flex{
			display: flex;
			align-items: center;
			height: calc(100vh - 72px);

			@include break(small-screen){
				height: auto;
				display: block;
			}

			@include break(mobile){
				padding: 20px 0;
			}
		}

		.click-section{
			&:before{
				content: '';
				
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		.left-side,
		.right-side{
			width: 50%;
			text-align: center;

			@include break(small-screen){
				width: 100%;
				position: relative;
				padding: 80px 0;
			}

			@include break(mobile){
				padding: 40px 0;
				padding-right: 30px;
				border-radius: 8px;
				background-image: none;
			}

			&:hover{
				.image-left,
				.image-right{

					&:before{
						background: linear-gradient(180deg, rgba(7, 6, 11, 0) 47.04%, rgba(7, 6, 11, 0.76) 100%);
						
						@include break(mobile){
							background-color: rgba(255, 255, 255, 0.05);
						}
					}
				}

				.product-img-holder{
					opacity: 0;
				}

				a.btn{
					background: $green;
					border-color: $green;

					&:before{
						content: '';
						
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
					}
					
					&:hover{
						color: $dark;
					}
				}
			}

			.text-box{
				max-width: 476px;
				color: white;
				position: relative;
				z-index: 2;

				@include break(small-screen){
					width: 100%;
					margin: 0 auto;
				}

				@include break(mobile){
					display: flex;
					align-items: center;
					position: static;
				}

				.product-img-holder{
					width: 300px;
					height: 300px;
					margin-bottom: 40px;
					margin-left: auto;
					margin-right: auto;
					transition: .4s;
					display: flex;
					justify-content: center;
					align-items: center;

					@include break(mobile){
						margin-bottom: 0;
						width: 230px;
						height: 230px;
					}

					@include break(mobile){
						width: 190px;
						height: 190px;
					}

					img{
						max-width: 80%;
						max-height: 230px;
						height: auto;
						display: block;
						margin: auto;

						@include break(mobile){
							max-height: 190px;
						}
					}
				}

				.title, h1{
					margin-bottom: 30px;

					@include break(mobile){
						max-width: 200px;
					}
				}

				.description, p{
					margin-bottom: 40px;

					 @include break(mobile){
					 	display: none;
					 }
				}

				a.btn{
					padding: 18px 88px;
					transition: .4s;

					@include break(mobile){
						padding: 0;
						height: 0;
						font-size: 0;
						width: 0;
						border: none;
					}

					&:before{
						@include break(mobile){
							content: '';
							position: absolute;
							left: 0;
							top: 0;
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}

		.left-side{
			@include break(mobile){
				margin-bottom: 20px;
			}
		}

		.right-side{
			.text-box{
				margin-left: auto;
			}
		}


		.image-left,
		.image-right{
			width: 50vw;
			height: 100%;
			position: absolute;
			top: 0;

			@include break(small-screen){
				right: auto !important;
				left: 50% !important;
				transform: translateX(-50%);
				height: 100%;
				width: calc(100% + (100vw - 100%));
			}

			@include break(mobile){
				width: 100%;
				background-image: none !important;
				border-radius: 8px;
			}

			&:before{
				content: '';
				background: rgba(7,6,11,1);
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				transition: .4s;
				display: block;

				@include break(mobile){
					border-radius: 8px;
					background-color: rgba(255, 255, 255, 0.05);
				}
			}
		}

		.image-left{
			right: 50%;
		}

		.image-right{
			left: 50%;
		}
	}
}



















