//PRODUCT ARCHIVE (BOTH)
.archive-products-fresh,
.archive-products-dry {
  background: $dark;
  color: white;

  .woocommerce-result-count {
    @include font-secondary-regular;
    font-size: 16px;
  }

  section.products-list-section {
    padding: 30px 0;

    @include break(v-mobile) {
      padding: 20px 0;
    }
  }

  .woof_products_top_panel {
    display: none !important;
  }

  .woof_radio_term_reset {
    position: relative;
    width: 20px;

    img {
      display: none;
    }

    &:before{
      content: '';
      color: $dark-green;

      width: 15px;
      height: 15px;
      background: white;
      border-radius: 50%;
      font-size: 12px;
      position: absolute;
      right: 0;
      top: -1px;
      transform: translateY(-50%);
    }

    &:after{
      content: 'x';
      color: $dark-green;
      font-size: 12px;
      position: absolute;
      right: 0;
      top: -1px;
      transform: translate(-4px, -52%);
      font-weight: 600;
    }
  }

  //ASIDE AREA (Radio/checkboxes)
  .woof_redraw_zone {
    @include break(mobile) {
      background: $white;
      height: 100vh;
      width: 100vw;
      padding: 20px 22px;
      overflow-y: hidden;
      position: fixed;
      right: 100%;
      top: 0;
      opacity: 1;
      transition: 0.3s;
      z-index: 30;
    }

    & > * {
      @include break(mobile) {
        color: $dark;
      }
    }

    .info-first {
      display: none;
    }

    .woof_container {
      margin-bottom: 40px;

      @include break(mobile) {
        margin-bottom: 0;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(7, 6, 11, 0.4);
      }

      h4 {
        @include font-secondary-semibold;
        font-size: 14px;
        margin-bottom: 16px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        position: relative;

        @include break(mobile) {
          margin-bottom: 0;
          padding: 20px 0 10px;
        }

        &:before {
          content: "";
          border-right: 2px solid $dark;
          border-bottom: 2px solid $dark;
          width: 7px;
          height: 7px;
          position: absolute;
          right: 10px;
          top: 26px;
          transform-origin: center;
          transform: rotate(45deg);
          transition: 0.3s;
        }
      }

      ul {
        li {
          font-size: 14px;

          .woof_checkbox_label_selected {
            color: $green;
          }
        }
      }

      .woof_block_html_items {
        @include break(mobile) {
          display: none;
        }
      }

      &.is-active {
        h4 {
          &:before {
            transform: rotate(-135deg);
          }
        }
      }
    }

    .woof_container_0 {
      .woof_block_html_items {
        display: block;
      }
    }

    .panel-header {
      display: none;

      @include break(mobile) {
        display: flex;
        justify-content: space-between;
        padding-bottom: 19px;
        border-bottom: 1px solid $dark;
      }

      .close-icon {
        width: 20px;
        height: 20px;
        position: relative;

        &:before,
        &:after {
          content: "";
          height: 2px;
          width: 100%;
          position: absolute;
          background: $dark;
          transform-origin: center;
          left: 0;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }

        &:after {
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }

    .woof_reset_search_form {
      @include font-secondary-semibold;
      font-size: 12px;
      background: transparent;
      text-decoration: underline;
      margin-left: auto;
      display: block;
      padding: 20px;
      display: flex;
      align-items: center;
      border: none;

      .icon-holder {
        margin-right: 12px;
      }
    }

    .woof_submit_search_form_container {
      height: 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .btn-valider {
      display: none;

      @include break(mobile) {
        display: inline-block;
      }
    }
  } //.woof_redraw_zone

  .mobile-btn-filter {
    display: flex;
    align-items: center;
    color: white;
    background: transparent;
    padding: 16px 29px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    display: none;

    @include break(mobile) {
      display: block;
    }

    .icon-holder {
      margin-right: 17px;
    }
  }

  .product-search-page {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include break(mobile) {
      display: block;
    }

    & > div:first-of-type {
      padding-right: 20px;
      border-right: 1px solid $white;

      @include break(mobile) {
        border: none;
        padding: 0;
      }
    }

    & > div:nth-of-type(2) {
      flex: 1;
      padding-left: 40px;

      @include break(tablet) {
        padding-left: 20px;
      }

      @include break(mobile) {
        padding-top: 80px;
        padding-left: 0;
      }
    }
  }

  .woocommerce-result-count {
    margin-bottom: 45px !important;
    font-weight: 400;

    @include break(mobile) {
      font-size: 14px;
      position: absolute;
    }
  }

  .woocommerce .products ul,
  .woocommerce ul.products {
    display: flex !important;
    justify-content: space-between !important;
    justify-content: flex-start !important;
    flex-wrap: wrap;

    li.product {
      background-color: rgba(255, 255, 255, 0.04);
      color: white;
      min-height: 434px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 8px;
      width: calc((100% - 60px) / 3);
      max-width: 295px;
      padding: 25px;
      padding-top: 54px;
      position: relative;
      padding-bottom: 100px;
      transition: 0.25s;
      margin-bottom: 20px !important;
      margin-right: 20px !important;

      @include break(small-screen) {
        //change for 2 col
        width: calc((100% - 60px) / 2);
      }

      @include break(tablet) {
        //change for 1 col
        width: 100%;
        max-width: none;
        padding: 30px 20px;
        min-height: 200px;
        margin-right: 0 !important;
      }

      @include break(mobile) {
        width: 100% !important;
        padding: 20px;
      }

      @include break(v-mobile) {
        padding: 15px;
        min-height: 190px;
      }

      &.in_cart {
        border-color: $green;

        .attachment-woocommerce_thumbnail {
          filter: blur(2px);
          position: relative;
        }

        .mito_imagewrapper {
          position: relative;

          &:before,
          &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 20;
            transform: translate(-50%, -50%);
          }

          &:before {
            content: "";
            width: 45px;
            height: 45px;
            border-radius: 35%;
            background: $green;

            @include break(tablet) {
              width: 40px;
              height: 40px;
            }
          }

          &:after {
            content: url(../img/icon_basket_white.svg);
            line-height: 1;
            z-index: 22;
          }
        }
      }

      .tag_list {
        width: 100%;
        position: absolute;
        padding: 25px 25px 0;
        top: 0;
        left: 0;

        @include break(tablet) {
          display: none;
        }

        .mito_product_tag.sale_tag {
          top: 25px;
          right: 25px;
        }
      }
    }
  }

  .woocommerce-LoopProduct-link {
    @include break(tablet) {
      display: flex;
      align-items: flex-start;
    }
  }

  //CARD IMAGE
  .woocommerce ul.products li.product a img {
    // max-width: 146px;
    max-width: 100%;
    height: auto;
    margin: 35px auto;

    position: relative;

    @include break(tablet) {
      width: 126px !important;
      margin: 0;
      // margin-right: 40px;
    }

    @include break(v-mobile) {
      width: 100px !important;
    }
  }

  //TITLE
  .woocommerce ul.products li.product .woocommerce-loop-category__title,
  .woocommerce ul.products li.product .woocommerce-loop-product__title,
  .woocommerce ul.products li.product h3 {
    @extend .h5;
    padding: 0;
    margin-bottom: 14px;

    @include break(tablet) {
      padding-top: 40px;
    }

    @include break(mobile) {
      padding-top: 30px;
    }

    @include break(v-mobile) {
      padding-top: 20px;
    }
  }

  //QUANTITY
  .variation_choice_price {
    margin-bottom: 20px;
    display: flex;

    @include break(tablet) {
      padding-left: 158px;
      margin-bottom: 45px;
    }

    @include break(v-mobile) {
      padding-left: 108px;
    }
  }

  .bulle_quantity {
    @include font-secondary-medium;
    background-color: transparent;
    color: $white;
    margin-right: 8px;
    padding: 4px 8px;
    font-size: 12px;
    border: 1px solid $white;
    border-radius: 4px;
    transition: 0.25s;
    cursor: pointer;

    @include break(v-mobile) {
      font-size: 10px;
    }
  }

  .selected_bulle_quantity {
    background-color: $white;
    color: $dark;
  }

  //PRICE and ADD INPUT
  .sale_price_box {
    position: absolute;
    left: 25px;
    bottom: 25px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @include break(tablet) {
      bottom: 20px;
      left: 178px;
    }

    @include break(mobile) {
      left: 178px;
    }

    @include break(v-mobile) {
      left: 126px;
      bottom: 15px;
    }
  }

  .mito_add_to_cart_button {
    @extend .btn;
    @extend .btn--primary;
    @extend .small;
    position: absolute;
    right: 25px;
    bottom: 25px;
    cursor: pointer;

    @include break(small-screen) {
      padding: 11px 23px;
      font-size: 13px;
    }

    @include break(tablet) {
      bottom: 20px;
      padding: 8px 11px;
    }

    @include break(v-mobile) {
      bottom: 15px;
    }
    &.out-of-stock {
      background: $btn-sec-on-light-hover;
      border: 1px solid $btn-sec-on-light-hover;
      margin-top: 20px;
      &:hover  {
        background: $btn-sec-on-dark-hover;
        border: 1px solid $btn-sec-on-dark-hover;
      }
    }
  }

  .mito_regular_price {
    @include font-secondary-semibold;
    font-size: 24px;

    @include break(tablet) {
      font-size: 20px;
    }

    &.salePrice {
      color: $green;
    }

    &.discountPrice {
      font-size: 14px;
      font-weight: 400;
      text-decoration: line-through;
    }
  }

  .input-group-add-remove {
    position: absolute;
    right: 25px;
    bottom: 25px;
    font-size: 24px;
    max-width: 100px;

    @include break(tablet) {
      bottom: 20px;
      max-width: 90px;
    }

    .button-plus,
    .button-minus {
      padding: 0 11px;
    }
  }
}

body.filter-box-is-open {
  .woof_redraw_zone {
    right: 0;
    opacity: 1;
  }
}

//PRODUCTS ARCHIVE (FRESH PROD. SPECIFIC)
.archive-products-fresh {
}

//PRODUCTS ARCHIVE (FDRY PROD. SPECIFIC)
.archive-products-dry {
  .card-btn {
    @extend .btn;
    background-color: transparent;
    color: $white;
    border: 1px solid $white;
    position: absolute;
    right: 25px;
    bottom: 25px;
    padding: 11px 28px;
    font-size: 14px;
  }
}

//TAGS ON TOP
//ALSO FOR SINGLE PRODUCTS
.tag_list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-right: 60px;

  .mito_product_tag {
    @include font-secondary-regular;
    font-size: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    margin-right: 8px;
    padding: 6px 8px 6px 28px;
    align-items: center;
    justify-content: space-between;
    position: relative;

    img {
      max-width: 14px;
      max-height: 14px;
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
    }

    &.sale_tag {
      background: $green;
      font-size: 12px;
      font-weight: 600;
      padding: 5px 10px;
      margin-left: 25px;
      margin-right: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
    &--rupture {
      @include font-secondary-regular;
      font-size: 10px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 40px;
      margin-right: 8px;
      padding: 6px 8px;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &.out-of-stock {
        color: $green!important;
      }
    }
  }
}

form.woocommerce-ordering {
  margin-right: 36px !important;
  position: relative;

  @include break(tablet) {
    margin-right: 0 !important;
  }

  @include break(mobile) {
    transform: translateY(-132px);
    max-width: 165px;
    float: none !important;
    margin-left: auto !important;
  }

  &:before {
    content: "";
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    transform-origin: center;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 50%;
    right: 20px;
    display: block;
    transform: translateY(-50%) rotate(-45deg);
  }

  select.orderby {
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    border-radius: 4px;
    padding: 11px 20px;
    background: transparent;
    color: $white;
    @include font-secondary-semibold;
    font-size: 14px;
    position: relative;

    @include break(mobile) {
      max-width: 166px;
      padding: 17px 20px;
    }
  }

  option {
    position: absolute;
    top: 100%;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";

  &:focus,
  &:focus-visible,
  &:active {
    border: 1px solid $green;
    outline: none;
    border-radius: 4px;
  }
}

.woof_redraw_zone {
  input[type="radio"],
  input[type="checkbox"] {
    margin-right: 10px;
    height: 17px;
    width: 17px;
    -webkit-appearance: none;
    border-radius: 2px;
    border: 1px solid white;
    transition: 0.3s;
    cursor: pointer;

    @include break(mobile) {
      border: 1px solid $dark;
    }
  }

  input[type="radio"]:checked,
  input[type="checkbox"]:checked {
    border-width: 5px !important;
    border-radius: 3px !important;
    border-color: $green !important;
    background: $white !important;
  }

  //MODIF NEW (to be approved)
  input[type="radio"],
  input[type="radio"]:checked{
    border-radius: 50% !important;
  }

  label {
    font-weight: 400;
  }

  ul.woof_list {
    li {
    }
  }
}

.mito_imagewrapper {
  @include break(tablet) {
    margin-right: 25px;
    min-width: 126px;
    max-width: 126px;
    height: 126px;
  }

  @include break(mobile) {
    margin: auto;
    height: auto;
    min-width: 90px;
    max-width: 90px;
  }
}
