aside#mito_sliding_cart {
  @include font-secondary-regular;
  // height: calc(100vh - 80px);
  height: 100%;
  width: 40vw;
  max-width: 600px;
  position: fixed;
  top: 0px;
  right: -40vw;
  z-index: 1000;
  background-color: $white;
  color: $dark;
  transition: all, 0.8s;
  // padding: 30px 20px;
  // right: 0 !important;
  border-radius: 8px 0 0 8px;

  @include break(small-screen) {
    width: 500px;
    right: -500px;
  }

  @include break(v-mobile) {
    width: 100%;
    right: -100%;
  }

  button.mito_close_cart_button {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 27px;
    right: 20px;
    background-color: transparent;
    border: none;

    &:before,
    &:after {
      content: "";
      background: $dark;
      display: block;
      height: 2px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      transform-origin: center;
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  #mito_sliding_cart_point_vente {
    height: 100%;
    position: relative;
  }

  .cart_info_box {
    border-bottom: 1px solid rgba(7, 6, 11, 0.05);
    margin-bottom: 40px;
    padding: 27px 30px 20px 20px;
    position: relative;

    h2 {
      font-size: 20px;
      margin-bottom: 15px;
    }

    p {
      font-size: 12px;
    }

    &__bottom {
      padding: 10px 30px 20px 20px;
    }
  }

  .button-holder {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-top: 1px solid rgba(7, 6, 11, 0.05);
    padding: 20px;
    margin-bottom: 0;

    button {
      width: 100%;
    }
  }
}

form#set_point_vente_form {
  padding: 0 20px;

  & > div {
    margin-bottom: 20px;
    width: 100%;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      border-left: 2px solid $dark;
      border-bottom: 2px solid $dark;
      position: absolute;
      right: 20px;
      bottom: 32%;
      transform: rotate(-45deg);
      transform-origin: center;
      z-index: 200;
    }
  }

  label {
    @extend .p-sm;
    font-weight: 600;
    margin-bottom: 5px;
    display: block;
  }

  select,
  input {
    width: 100%;
    padding: 15px 20px;
    border-radius: 4px;
    border: 1px solid rgba(7, 6, 11, 0.05);
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    position: relative;

    &:focus,
    &:focus-visible,
    &:active {
      border: 1px solid $green;
      outline: none;
      border-radius: 4px;
    }
  }
}

//VOTRE COMMANDE (second aside - after choosing pick-up)
#mito_sliding_cart_woo_cart {
  height: 100%;
  background: $pale;
  height: 100vh;
  border-radius: 8px;

  //Header part
  .woo_cart_info_box {
    padding: 27px 20px 40px;
    background: $white;
    border-radius: 8px;
    position: relative;

    h2 {
      font-size: 20px;
      margin-bottom: 27px;
    }

    p {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 5px;

      &.location,
      &.time {
        display: flex;
        align-items: center;
        transform: translateY(3px);

        &:before {
          content: url(../img/icon_pin_dark.svg);
          margin-right: 11px;
          display: block;
          transform: translateY(3px);
        }
      }

      &.location {
      }

      &.time {
        margin-bottom: 14px;

        &:before {
          content: url(../img/icon_time_dark.svg);
        }
      }
    }

    #mito_cart_button_go_to_point_vente {
      color: $green !important;
      text-decoration: underline;
      display: inline-block;
      background: transparent;
      border: none;
      cursor: pointer;
    }
  } //.woo_cart_info_box

  //CENTER PART
  .list_cart_product {
    overflow-y: scroll;
    height: 60vh;
    padding: 32px 20px;

    .products-count {
      margin-bottom: 13px;

      p {
        font-size: 12px;
      }
    }

    //CARDS
    .product_in_cart {
      width: 100%;
      border: 1px solid rgba(7, 6, 11, 0.05);
      border-radius: 8px;
      padding: 20px;
      position: relative;
      display: flex;
      margin-bottom: 18px;

      .product_img_wrapper {
        width: 95px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 70px;
          height: auto;
          max-height: 70px;
          display: block;
        }
      }

      .product_info_txt {
        flex: 1;
        padding-left: 12px;
        position: relative;

        h3 {
          @include font-secondary-semibold;
          font-size: 16px;
          max-width: calc(100% - 40px);
          margin-bottom: 6px;
        }

        p.product_quantity {
          font-size: 12px;
          margin-bottom: 19px;
        }
      }

      .delete_mito_product_cart {
        position: absolute;
        right: 0;
        top: 0;
        background: transparent;
        border: none;
      }

      .sale_price_box {
        position: absolute;
        right: 0;
        bottom: 5px;

        .salePrice {
          color: $green;
          font-size: 16px;
          font-weight: 600;
        }

        .discountPrice {
          color: rgba(7, 6, 11, 0.4);
          font-size: 12px;
          font-weight: 400;
          text-decoration: line-through;
        }

        .normal-price {
          color: $dark;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .text-wrapper-empty {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .textbox {
        max-width: 290px;
        text-align: center;

        .icon-holder {
          background: $white;
          width: 49px;
          height: 49px;
          border-radius: 35%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto 16px;
        }

        p {
          font-size: 20px;
        }

        .btn {
          font-weight: 400;
        }
      }
    }
  }

  .aside-cart-footer {
    background: $white;
    border-top: 1px solid rgba(7, 6, 11, 0.05);
    padding: 18px 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0 0 0 8px;

    .text-holder {
      display: flex;
      justify-content: space-between;

      .subtitle {
        font-size: 10px;
        margin-bottom: 16px;
      }

      .price {
        font-size: 22px;
        font-weight: 600;
      }
    }

    .btn-holder {
      button {
        width: 100%;
      }
    }
  }
}

.btn-cart-empty {
  margin-top: 30px;
}
.in_cart {
  border: 4px solid #0cff0c;
  border-radius: 6px;
}

.input-group-add-remove {
  max-width: 80px;
  border: 1px solid $dark;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  border-radius: 4px;

  .mito_update_product_quantity {
    width: 25px;
    text-align: center;
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;

    @include break(tablet) {
      width: 18px;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input {
    padding: 0;
    background: transparent;
    border: none;

    &.button-minus,
    &.button-plus {
      padding: 0 8px;
      cursor: pointer;
    }
  }

  &.light {
    border: 2px solid rgba(255, 255, 255, 0.2);
    color: white;

    & > * {
      color: white;
    }
  }
}

.cart-collaterals {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .checkout-button {
    background: $green !important;
  }
}
