.page-about{
	background-color: $pale;

	.main-banner{
		height: calc(100vh - 80px);
		position: relative;
		display: flex;
		align-items: center;
		overflow-x: hidden;

		@include break(small-screen){
			display: block;
			height: auto;
		}

		.breadcrumb-theme{
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}


		.container-md{
			@include break(small-screen){
				padding: 100px 0;
			}
		}

		.textbox{
			max-width: 510px;

			@include break(small-screen){
				text-align: center;
				margin-left: auto;
				margin-right: auto;
			}

			p{
				margin-bottom: 40px;
			}
		}

		.img-side{
			width: 50vw;
			height: 100%;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(100px);

			@include break(small-screen){
				position: static;
				height: 50vw;
				width: 100%;
				transform: translateX(0);
				background-position: center;
				background-position-y: 77%;
			}
		}
	}

	section.value {
		.title_container {
			margin-bottom: 60px;
			padding-right: 30%;
			@include break(tablet){
				padding-right: 0%;
			}
		}
		.faq-block{
			margin-bottom: 80px;
	
			@include break(tablet){
				margin-bottom: 40px;
			}
	
			@include break(v-mobile){
				margin-bottom: 40px;
			}
	
			h3{
				margin-bottom: 40px;
			}
	
			ul.faq-list{
				list-style: none;
	
				& > li{
					margin-bottom: 24px;
					background: $white;
					border-radius: 8px;
					transition: .3s;
					cursor: pointer;
	
					@include break(v-mobile){
						 margin-bottom: 14px;
					}
	
					&:hover{
						box-shadow: 0px 4px 8px rgba(7, 6, 11, 0.04);
					}
	
					&.active{
						box-shadow: 0px 4px 8px rgba(7, 6, 11, 0.04);
	
						.question{
							color: $green;
	
							.icon-holder{
								transform: translateY(-50%) rotate(45deg);
							}
						}
					}
				}
	
				.question{
					min-height: 105px;
					padding: 20px 180px 20px 40px;
					display: flex;
					align-items: center;
					position: relative;
	
					@include break(tablet){
						padding: 20px 100px 20px 40px;
						min-height: 90px;
					}
	
					@include break(mobile){
						padding: 20px 60px 20px 20px;
						min-height: 80px;
					}
	
					span.icon-holder{
						width: 28px;
						height: 28px;
						position: absolute;
						top: 50%;
						right: 40px;
						transform: translateY(-50%) rotate(0deg);
						display: block;
						transition: .3s;
	
						@include break(tablet){
							width: 24px;
							height: 24px;
							right: 30px;
						}
	
						@include break(v-mobile){
							width: 18px;
							height: 18px;
							right: 20px;
						}
	
						&:before,
						&:after{
							content: '';
							background: $dark;
							height: 4px;
							width: 100%;
							position: absolute;
							left: 0;
							top: 50%;
							display: block;
							
						}
	
						&:before{
							transform: translateY(-50%) rotate(90deg);
						}
	
						&:after{
							transform: translateY(-50%);
						}
					}
				}
	
				.answer{
					padding: 10px 80px 40px 40px;
					display: none;
	
					@include break(tablet){
						padding: 10px 35px 10px;
					}
	
					@include break(mobile){
						padding: 10px 20px 20px;
					}
	
					a{
						color: $green;
						text-decoration: underline;
					}
	
					p{
						margin-bottom: 20px;
	
						&:last-of-type{
							margin-bottom: 0;
						}
					}
	
					ul, ol{
						padding: 10px 0;
	
						li{
							margin-bottom: 8px;
						}
					}
				}
			}
		}
	}


	

	section.our-mission{

		padding: 80px 0 120px 0; //v1 while no bg

		background-size: contain; //to be confirmed
		background-repeat: no-repeat;
		background-image: none !important; //for v1 remove bg

		@include break(tablet){
			padding: 100px 0;
		}

		@include break(v-mobile){
			padding: 40px 0;
		}

		.highlight-quote{
			margin-left: auto;

			.text-box{
				display: flex;
				justify-content: center;
				align-items: center;

				@include break(v-mobile){
					display: block;
					text-align: center;
				}
				
				h4{
					max-width: 450px;
				}
			}

			.icon-holder{
				width: 150px;
				margin-right: 35px;
				position: relative;

				@include break(mobile){
					width: 120px;
					min-width: 120px;
				}

				@include break(v-mobile){
					width: 90px;
					min-width: 90px;
					margin: 0 auto 20px;
				}

				.icon-item{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					max-width: 77px;
					max-height: 80px;
				}
			}
		}

		.textbox{
			width: 100%;
			background: $white;
			min-height: 220px;
			padding: 100px 107px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			border-radius: 8px;
			flex-wrap: wrap;

			@include break(tablet){
				padding: 100px 50px;
			}

			@include break(v-mobile){
				padding: 80px 22px;
				min-height: 0;
			}

			h2{
				margin-right: 40px;
				margin-bottom: 40px;

				@include break(mobile){
					margin-bottom: 20px;
				}
			}

			p{
				max-width: 460px;
			}
		}
	}

	section.certifications{
		margin: 0 0 160px 0;

		.flex-parent{
			display: flex;

			@include break(tablet){
				display: block;
			}

			.left-side{
				width: 400px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-right: 1px solid $dark;

				@include break(tablet){
					border: none;
					width: 100%;
					margin-bottom: 40px;
				}

				.textbox{
					max-width: 270px;
					text-align: center;
				}
			}

			.right-side{
				flex: 1;
				padding: 20px 0;

				.flex{
					display: flex;
					justify-content: center;
					flex-wrap: wrap;

					.item{
						margin-bottom: 40px;
						width: 50%;

						.card{
							max-width: 180px;
							margin: auto;
							text-align: center;

							@include break(mobile){
								max-width: 150px;
							}

							.icon-holder{
								position: relative;

								.icon-bg{
									max-width: 185px;
								}

								.icon{
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								}

								img{
									max-width: 100%;
									height: auto;
									display: block;
									margin: auto;
								}
							}

							.info{
								padding: 20px 5px;

								p{
									max-width: 190px;
									margin: auto;
								}
							}
						}
					}
				}
			}
		}
	}//section Certifications

	section.slider-history{
		padding: 150px 0 0 0;
		display: flex;
		align-items: center;

		@include break(tablet){
			display: block;
			padding-top: 0;
			padding-bottom: 350px;
		}

		.dates_wrapper {
			width: 85px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			margin-right: 15%;
			overflow: visible;

			@include break(tablet){
				height: 190px !important;
				flex-direction: row;
				width: 100%;
			}

			.date {
				@include font-secondary-bold ;
				font-size: 1.125em;
				color: $dark;
				opacity: 0.4;
				transition: all 0.4s;
				cursor: pointer;

				@include break(tablet){
					margin-right: 10px;
					margin-left: 10px;
				}

				&:hover {
					opacity: 1;
				}

				&.active {
					font-size: 2em;
					opacity: 1;
					pointer-events: none;
				}
			}

			.line {
				height: 0px;
				width: 1px;
				display: block;
				background-color: $dark;
				margin-top: 10px;
				margin-bottom: 10px;
				transition: all 0.4s;

				@include break(tablet){
					display: none;
				}

				&.active {
					height: 26px;
				}
			}
		}
		.sliders_wrapper {
			width: 100%;
			flex-shrink: 1;
			position: relative;
			height: 560px;
		}

		.slider_swipe {
			justify-content: space-between;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			display: none;

			&:first-of-type {
				opacity: 1;
				display: flex;
			}

			@include break(tablet) {
				flex-direction: column;
			}

			.slider_left {
				width: 45%;
				padding-bottom: 53%;
				position: relative;
				cursor: grab;

				@include break(small-screen){
					width: 38%;
				}

				@include break(tablet){
					width: 100%;
				}

				&:active {
					cursor: grabbing;
				}

				.slider_arrow {
					position: absolute;
					@include absolute-center(center);
					z-index: 9;
					opacity: 0;
					transition: all 0.4s;
					pointer-events: none;
				}

				.slide_img {
					background-position: center;
					background-size: cover;
					height: 560px;
					width: 400px;
					position: absolute;
					@include absolute-center(center);
					opacity: 0;
					pointer-events: none;

					@include break(small-screen){
						width: 300px;
						height: 500px;
					}

					&:nth-of-type(1) {
						opacity: 1;
					}

					&:nth-of-type(2) {
						transform: translate(-50%,-50%) rotate(10deg);
						opacity: 1;
					}
					&:nth-of-type(3) {
						opacity: 1;
						transform: translate(-50%,-50%) rotate(-10deg);
					}
				}
			}
			.slider_right {
				padding-top: 5%;
				padding-bottom: 5%;
				margin-right: 5%;
				width: 45%;
				color: $dark;

				@include break(small-screen){
					padding-right: 40px;
				}

				@include break(tablet){
					width: 100%;
					max-width: 400px;
					padding-top: 100px;
					padding-right: 0;
					margin: 0 auto;
					text-align: center;
				}

				.num {
					@include font-secondary-semibold;

					display: none; //needing fix

					.txt {
						display: inline-block;
						width: 12px;
						text-align: center;
						color: $green;
						font-size: 24px;
					}
				}

				.slider_contentWrap {
					height: 100%;
					position: relative;
				}

				.slide_content {
					width: 100%;
					position: absolute;
					@include absolute-center(center);
					opacity: 0;

					@include break(tablet){
						top: 0;
						left: 0;
						transform: none;
					}

					h3 {
						margin-bottom: 25px;
					}

					.btn {
						margin-top: 40px;
					}

					&:first-of-type {
						opacity: 1;
					}
				}
			}

			&:hover .slider_arrow {
				opacity: 1;
			}
		}
	}
}
