.page-contact{

	background: $pale;

	section.contact-section-main{
		overflow-x:hidden;

		@include break(tablet){
			padding: 100px 0;
		}

		.parent-flex{
			display: flex;
			justify-content: space-between;

			@include break(tablet){
				flex-wrap: wrap;
				flex-direction: column-reverse;
			}

			.left-side{
				width: 330px;
				padding: 350px 0 0;
				padding-right: 30px;
				position: relative;

				@include break(tablet){
					width: 100%;
					padding: 0;
				}

				.img-building{
					position: absolute;
					top: 0;
					right: 0;
					width: auto;
					max-width: none;

					@include break(tablet){
						display: none;
					}
				}

				&:before{
					content: '';
					background: $white;
					width: 50vw;
					height: 100%;
					position: absolute;
					right: 0;
					top: 0;

					@include break(tablet){
						display: none;
					}
				}

				.info-box{
					position: relative;

					@include break(tablet){
						display: flex;
						justify-content: space-between;
					}

					@include break(v-mobile){
						display: block;
					}
				}

				.section-title{
					padding-bottom: 20px;
					//border-bottom: 1px solid $black;
					margin-bottom: 60px;
					position: relative;
					display: none;

					@include break(tablet){
						display: block;
						text-align: center;
					}

					@include break(v-mobile){
						text-align: left;
					}
				}

				.item{
					margin-bottom: 40px;

					@include break(tablet){
						width: calc((100% - 80px) / 3);
						text-align: center;
					}

					@include break(v-mobile){
						text-align: left;
						width: 100%;
					}

					.title{
						@include font-secondary-regular;
						font-size: 12px;
						margin-bottom: 15px;
						text-transform: uppercase;
					}

					p{
						@include font-secondary-bold;
						font-size: 16px;
					}
				}
			}

			.right-side{
				flex: 1;
				max-width: 620px;
				padding: 150px 0;
				padding-left: 145px;

				@include break(small-screen){
					padding-left: 70px;
				}

				@include break(tablet){
					max-width: none;
					padding: 0;
					margin-bottom: 80px;
				}

				.radios-holder{
					transform: translateX(7px);
				}

				.block-title{
					margin-bottom: 40px;
				}

				form{
					.btn-holder{
						margin-top: 40px;
					}

					.form-item{
						margin-bottom: 32px;

						&.has-radio{
							margin-bottom: 46px;

							input[type="radio"],
							input[type="checkbox"] {
							  margin-right: 10px;
							  height: 17px;
							  width: 17px;
							  -webkit-appearance: none;
							  border-radius: 50%;
							  border: 1px solid rgba(7, 6, 11, 0.4);
							  padding: 0 !important;
							  min-width: 0;
							  transition: .2s;
							  cursor: pointer;
							  transform: translateY(2px);
							}

							input[type="radio"]:checked,
							input[type="checkbox"]:checked {
							  border-width: 4px !important;
							  border-color: $green !important;
							  background: $white !important;
							}
						}
					}
				}
			}
		}
	}

	.wpcf7-radio.ratio--item{
		display: flex;

		.wpcf7-list-item{
			margin-left: 0 !important;

			&.first,
			&.last{
				display: flex
			}

			&.first{
				transform: translateX(-8px);
				margin-right: 14px;
			}
		}
	}

	.wpcf7-list-item-label{
		white-space: nowrap;
	}

	input[type=radio]{
		min-width: 27px;
		transform: translateY(5px);

	}	

}














