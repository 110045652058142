footer.main-footer{
	color: white;
	padding: 83px 0;

	.footer-flex{
		display: flex;
		justify-content: space-between;

		@include break(small-screen){
			display: block;
		}

		.logo-side{
			padding-right: 130px;
			border-right: 1px solid white;
			display: flex;
			align-items: center;

			@include break(small-screen){
				border-right: none;
				border-bottom: 1px solid white;
				padding-bottom: 60px;
				padding-right: 0;
			}

			@include break(mobile){
				max-width: 260px;
				margin: auto;
			}

			.logo-holder{
				width: 240px;

				@include break(small-screen){
					margin: auto;
				}

				@include break(tablet){
					width: 210px;
				}

				@include break(mobile){
					width: 180px;
				}

				@include break(v-mobile){
					width: 160px;
				}
			}
		}

		.navigation-side{
			padding: 40px 0 15px 130px;
			display: flex;
			justify-content: space-between;
			flex: 1;

			@include break(small-screen){
				padding: 40px 70px 15px;
			}

			@include break(mobile){
				flex-direction: column;
				align-items: center;
				padding: 30px 0;
			}

			.les-menus-wrapper{
				display: flex;

				@include break(mobile){
					width: 100%;
				}


				@include break(v-mobile){
					display: block;
				}

				.footer-menu-wrapper{
					padding-right: 100px;

					@include break(mobile){
						padding-right: 50px;
						padding-left: 50px;
					}

					@include break(v-mobile){
						margin-bottom: 20px;
					}
				}

				// .social-menu-wrapper{
				// 	padding-right: 100px;

				// 	@include break(mobile){
				// 		padding-top: 30px;
				// 		padding-right: 50px;
				// 		padding-left: 50px;
				// 	}

				// 	ul.footer-social-media{

				// 		@include break(mobile){
				// 			justify-content: center;
				// 		}

				// 		li{
				// 			margin-right: 32px;
				// 			margin-bottom: 25px;

				// 			@include break(mobile){
				// 				margin: 0;
				// 				margin-bottom: 20px;
				// 				text-align: center;
				// 			}

				// 			a{
				// 				color: white;
				// 				border-bottom: 1px solid transparent;

				// 				&:hover{
				// 					//opacity: 40%; //effet ancien
				// 					color: $green;
				// 				}
				// 			}
				// 		}
				// 	}
				// }
			}

			.footer-menu-holder{

				@include break(mobile){
					text-align: center;
					margin-bottom: 60px;
					padding-top: 30px;
				}

				li{
					margin-bottom: 25px;

					@include break(mobile){
						margin-bottom: 20px;
					}

					a{
						&:hover{
							// opacity: 40%; //hover ancien
							color: $green;
						}
					}
				}
			}

			.lang-switch{
				padding-top: 25px;
				font-size: 12px;
				@include font-secondary-regular;

				@include break(mobile){
					padding-top: 10px;
				}
			}

			.newsletter-and-social{
				width: 60%;
				@include break(mobile){
					width: 100%;
				}

				h4 {
					font-weight: 500;
				}

				#mc_embed_shell {
					padding-top: 30px;
					width: 100%;

					.input_wrap {
						display: flex;
						width: 100%;

						input {
							height: 48px;
							border-radius: 4px;
							outline: none;
							color: $white;
							&[type=email] {
								border: 1px solid rgba(255,255,255, 0.25);
								background-color: transparent;
								width: 100%;
								flex-shrink: 1;
								padding: 0 20px;
								transition: all 0.4s;
								&::placeholder {
									color: rgba(255,255,255, 0.25);
								}

								&:focus {
									border: 1px solid rgba(255,255,255, 1);
								}
							}
							&[type=submit] {
								background-color: $green;
								border: none;
								padding: 0 36px;
								flex-grow: 0;
								margin-left: -6px;
								transition: all 0.4s;

								&:hover, &:focus {
									background-color: $dark-green;
								}
							}
						}
					}
				}

				.social-menu-wrapper {
					padding-top: 45px;

					.footer-social-media {
						display: flex;
						width: 100%;
						justify-content: space-between;

						a {
							font-size: 14px;
							font-weight: 400;
							transition: all 0.4s;
							&:hover, &:focus {
								color: $dark-green;
							}
						}
					}
				}
			}

			.newsletter-holder{
				// width: 400px;

				// @include break(mobile){
				// 	margin-left: auto;
				// 	margin-right: auto;
				// 	max-width: 350px;
				// }


				// h5{
				// 	font-size: 16px;
				// 	font-weight: 500;
				// }

				// iframe{
				// 	border: none;
				// 	height: 260px;
				// 	width: 330px;

				// }

				// .form-item{
				// 	width: 100%;
				// 	position: relative;
				// 	margin-bottom: 45px;

				// 	input.email-field{
				// 		background: transparent;
				// 		width: 100%;
				// 		border: 1px solid rgba(255, 255, 255, 0.4);
				// 		color: white;
				// 		padding-right: 60px;
				// 		padding: 14px 150px 14px 20px;
				// 		border-radius: 4px;
				// 		outline: none;

				// 		&:focus{
				// 			outline: none;
				// 		}
				// 	}

				// 	input.btn{
				// 		position: absolute;
				// 		top: 0;
				// 		right: 0;

				// 		@include break(mobile){
				// 			font-size: 12px;
				// 			padding: 16px 18px;
				// 		}
				// 	}

				// 	::placeholder{
				// 		@extend .p-sm;
				// 		color: rgba(255, 255, 255, 0.4);
				// 		font-size: 14px;

				// 		@include break(mobile){
				// 			font-size: 12px;
				// 		}
				// 	}
				// }

				// .wpcf7-form{
				// 	position: relative;
				// }

				// .ajax-loader{
				// 	position: absolute !important;
				// 	top: 12px !important;
				// 	left: calc(100% + 10px) !important;
				// }

				// .wpcf7-response-output{
				// 	margin: 0 !important;
				// 	position: absolute !important;
				// 	top: 100% !important;
				// 	border: none !important;
				// 	padding: none !important;
				// 	color: $green !important;
				// 	font-size: 14px !important;
				// }
			}//newsletter-holder

		}//navigation-side
	}

	.footer_legal {
		.logo-side {
			border: none;
		}
		.navigation-side {
			display: block;
		}
		.menu-footer-legal-container ul, .menu-footer-legal-en-container ul {
			display: flex;
			width: 100%;
			@include break(small-screen){
				justify-content: center;
			}
		}
		li {
			width: 25.5%;
    	display: block;
			@include break(small-screen){
				width: auto;
				margin-left: 20px;
				margin-right: 20px;
			}

			a {
				font-size: 12px;
				text-decoration: underline;
			}
		}
	}
}


/* Borders guidelines
.red-b{border: 3px solid red;}
.blue-b{border: 3px solid blue;}
.green-b{border: 3px solid green;}
.gold-b{border: 3px solid gold;}
.magenta-b{border: 3px solid magenta;}
.cyan-b{border: 3px solid cyan;}
.orange-b{border: 3px solid orange;}
.container{border: 6px solid purple;}
.container-lg{border: 6px solid yellow;}
*/
