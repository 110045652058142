.wc_payment_methods,
.woocommerce-terms-and-conditions-wrapper {
  display: none;
}
.woocommerce-billing-fields {
  display: none;
}

.page-checkout{
	background: $pale;
	color: $dark;
	position: relative;
  min-height: 900px;

	.mito_checkout{
		display: flex;
		min-height: calc(100vh - 540px);
		padding-top: 60px;
		padding-bottom: 100px;
		
		@include break(tablet){
			display: block;
		}

		@include break(v-mobile){
			padding-bottom: 20px;
		}
	}

	//LEFT SIDE
	.checkout_partie_gauche{
		width: 730px;

		@include break(small-screen){
			width: 52vw;
		}

		@include break(tablet){
			margin-bottom: 40px;
			width: 100%;
		}

		.section-header{
			margin-bottom: 40px;

			h2{
				margin-bottom: 20px;
			}

			p{
				font-size: 14px;
			}
		}

		.add-note{
			.title{
				margin-bottom: 12px;
			}

			textarea{
				background: transparent;
				resize: none;
				width: 100% !important;
				padding: 15px 20px;
				border: 1px solid rgba(7, 6, 11, 0.15);
				border-radius: 4px;
			}
		}
	}

	//RIGHT SIDE
	.checkout_partie_droite{
		width: 409px;
		position: absolute;
		top: 0;
		right: 0;
		background: white;
		padding: 137px 36px 40px;

		@include break(small-screen){
			padding-top: 80px;
		}

		@include break(tablet){
			position: static;
			max-height: none;
			width: 100%;
			overflow-y: initial;
		}

		@include break(mobile){
			padding: 32px 22px;
		}


		h5{
			@include font-secondary-semibold;
			margin-bottom: 40px;
		}

		.price-details{
			margin-bottom: 40px;

			hr{
				margin-bottom: 20px;
			}

			.item{
				display: flex;
				justify-content: space-between;
				margin-bottom: 18px;

				p{
					font-size: 14px;

					&.value{
						font-weight: 600;
					}
				}
			}
		}

		.store-info{
			background: rgba(7, 6, 11, 0.05);
			padding: 28px;
			border-radius: 8px;
			margin-bottom: 100px;

			@include break(small-screen){
				margin-bottom: 70px;
			}

			@include break(mobile){
				margin-bottom: 20px;
			}

			.name-and-location,
			.timetable{
				display: flex;

				.icon-holder{
					width: 13px;
					margin-right: 17px;
				}

				.txt-side{

					.txt-title{
						font-size: 14px;
						font-weight: 600;
						margin-bottom: 8px;
					}

					.location{
						font-size: 12px;
					}
				}
			}

			.name-and-location{
				margin-bottom: 22px;
			}
		}

		.woocommerce-checkout-payment{
			background: transparent !important;

			.form-row.place-order{
				padding: 0 !important;
				margin: 0 !important;

				.btn--primary{
					background: $green !important;
					font-weight: 400 !important;
					padding: 14px !important;
					width: 100%;
					margin-bottom: 14px;
				}
			}
		}

		.btn--secondary{
			width: 100% !important;
			padding: 14px !important;
			font-weight: 400 !important;
		}

		.extra-info{
			p{
				color: rgba(7, 6, 11, 0.6);
				font-size: 12px;
				margin-top: 20px;
			}
		}
	} //right side
}


.page-checkout{
	table{
		width: 100%;
		border-collapse: collapse;
		margin-bottom: 40px;

		tr{
			padding: 40px 0;
			border-bottom: 2px solid rgba(7, 6, 11, 0.05);

			&:first-of-type{
				@include break(v-mobile){
					display: none;
				}
			}

			th{
				color: rgba(7, 6, 11, 0.4);
				font-size: 10px;
				text-transform: uppercase;
				text-align: left !important;
				padding-bottom: 10px;
			}
		}

		td{
			padding: 35px 0;
			white-space: nowrap;
		}

		p{
			@include break(v-mobile){
				font-size: 13px;
			}
		}

		.quantity{
			padding: 0 20px;
		}

		.bold{
			font-weight: 600;
			font-size: 16px;

			@include break(v-mobile){
				font-size: 13px;
			}
		}

		.product_description_image {
			display: flex;


			@include break(v-mobile){
				width: 180px;
				align-items: center;
			}

			@include break(v-mobile){
				width: auto;
				padding-right: 20px;
			}

			.img-holder{
				width: 80px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 15px;

				@include break(v-mobile){
					width: 50px;
					margin-right: 8px;
				}

				img{
					max-width: 65px;
					height: auto;
					max-height: 80px;
					display: block;
				}
			}

			.product-name{
				font-size: 16px;
				margin-bottom: 4px;
				font-weight: 600;
				max-width: 170px;
				white-space: break-spaces;

				@include break(v-mobile){
					font-size: 13px;
				}
			}

			.product-quant{
				font-size: 12px;
				font-weight: 500;
			}
		}
	}
}
