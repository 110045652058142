// POPUP MAKER

// TEMPLATE FOR POPUP - PROMO
.pum-theme-10759 .pum-container{
  background: white;
  padding: 0px!important;

  @include break(tablet) {
    max-width: 100%!important;
    width: 60%!important;
    top: 2rem!important;
    border-radius: 0!important;
    position: absolute!important;
    margin-left: auto!important;
    margin-right: auto!important;
    left: 0!important;
    right: 0!important;
    text-align: center!important;
  }

  @include break(mobile) {
    width: 90%!important;
    padding: 14px!important;
  }

  .pum-content + .pum-close {
    height: 40px!important;
    width: 40px!important;
    font-size: 40px!important;
  }

  .pum-content {

    .cols {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      align-items: center;
      @include break(tablet) {
        grid-template-columns: 1fr;
        grid-column-gap: 0rem;
        grid-row-gap: 2rem;
      }
      .col {
        &.image {
          background-size: cover;
          height: 100%;
          min-height: 60vh;
          background-position: center;

          @include break(tablet) {
            min-height: 50vh;
          }

          @include break(tablet) {
            min-height: 40vh;
          }

        }
        &.content {
          display: block;
          .text {
            padding: 2rem;
            @include break(tablet) {
              padding: 1rem;
            }
          }
          h3 {
            text-align: left;
            padding-bottom: 0px;
            margin-bottom: 0px;
            font-size: 28px;
            line-height: 38px;
            padding-bottom: 20px;
            @include break(tablet) {
              font-size: 18px;
              line-height: 22px;
            }

          }
          .bouton {
            background: #80c537;
            margin-top: 2rem;
            color: #ffffff;
            border: 1px solid #80c537;
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            font-size: 16px;
            background: #80c537;
            color: #ffffff;
            padding: 14px 35px;
            display: inline-block;
            border-radius: 4px;
            -webkit-transition: .25s;
            -o-transition: .25s;
            transition: .25s;
            @include break(tablet) {
              margin-bottom: 3rem;
              margin-left: 1rem;
            }
            &:hover {
              background: #74ab3a;
              border: 1px solid #74ab3a;
            }
          }
        }
      }
    }
  }
}

// TEMPLATE FOR POPUP - CONCOURS
.pum-theme-10817 .pum-container{
  background: white!important;
  padding: 0px!important;

  @include break(tablet) {
    max-width: 100%!important;
    width: 60%!important;
    top: 2rem!important;
    border-radius: 0!important;
    position: absolute!important;
    margin-left: auto!important;
    margin-right: auto!important;
    left: 0!important;
    right: 0!important;
    text-align: center!important;
  }

  @include break(mobile) {
    width: 90%!important;
    padding: 0px!important;
  }

  .pum-content + .pum-close {
    height: 40px!important;
    width: 40px!important;
    font-size: 40px!important;
    @include break(tablet) {
    color:white!important;
    }
  }

  .pum-content {

    .cols {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      align-items: center;
      @include break(tablet) {
        grid-template-columns: 1fr;
        grid-column-gap: 0rem;
        grid-row-gap: 2rem;
      }
      .col {
        &.image {
          background-size: cover;
          height: 100%;
          min-height: 60vh;
          background-position: center;

          @include break(tablet) {
            min-height: 50vh;
          }

          @include break(tablet) {
            min-height: 40vh;
          }

        }
        &.content {
          display: block;
          padding: 2rem;
          background: white;
          h3 {
            text-align: left;
            padding-bottom: 0px;
            margin-bottom: 0px;
            font-size: 28px;
            line-height: 38px;
            padding-bottom: 10px;
            @include break(tablet) {
              font-size: 18px;
              line-height: 22px;
            }
          }
          p {
            padding-bottom: 16px;
          }
          p.pum-form__message.pum-form__message--success {
            padding-bottom: 0px!important;
            color: #80c537!important;
          }
          input[type="text"], input[type="email"]{
            border: 1px solid #D4D5D7;
            border-radius: 4px;
            height: 50px;
            padding-left: 10px;
            &:focus {
              outline: none !important;
              border: 1px solid #80c537;
            }
          }
          input.wpcf7-form-control.has-spinner.wpcf7-submit, .pum-form--style-default button {
            background: #80c537;
            color: #ffffff;
            border: 1px solid #80c537;
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            font-size: 16px;
            background: #80c537;
            color: #ffffff;
            padding: 14px 35px;
            display: inline-block;
            border-radius: 4px;
            -webkit-transition: .25s;
            -o-transition: .25s;
            transition: .25s;
            @include break(tablet) {
              margin-bottom: 3rem;
            }
            &:hover {
              background: #74ab3a;
              border: 1px solid #74ab3a;
            }
          }
          .copyright {
            color: #98989B;
            margin-top: 20px;
            font-size: 10px;
            line-height: 13px;
            font-weight: 300;
          }
        }
      }
    }
  }
}
