.template-landing {

	section:not(.no-pad) {
		padding: 40px 0;

		&.big-padding-top {
			padding-top: 120px;
			@include break(small-screen){
				padding-top: 60px;
			}
		}
		&.big-padding-bottom {
			padding-bottom: 120px;
			@include break(small-screen){
				padding-bottom: 80px;
			}
		}
	}

	div.content_block {
		padding-bottom: 80px;

		&:last-of-type {
			padding-bottom: 0px;
		}
	}

	.btn {
		margin-top: 40px;
	}

	.main-banner{
		min-height: calc(100vh - 80px);
		position: relative;
		display: flex;
		align-items: center;
		overflow-x: hidden;

		h1 {
			padding-top: 60px;
			@include break(small-screen){
				padding-bottom: 0px;
			}
		}

		@include break(small-screen){
			display: block;
			height: auto;
			min-height: 0;
		}

		.breadcrumb-theme{
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}


		.container-md{
			@include break(small-screen){
				padding: 100px 0;
			}
		}

		.textbox{
			max-width: 510px;

			@include break(small-screen){
				text-align: center;
				margin-left: auto;
				margin-right: auto;
			}

			p{
				margin-bottom: 40px;
			}
		}

		.img-side{
			width: 50vw;
			height: 100%;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(100px);

			@include break(small-screen){
				position: static;
				height: 50vw;
				width: 100%;
				transform: translateX(0);
				background-position: center;
				background-position-y: 77%;
			}
		}
	}

	//SECTION MAIN IMAGE
	.main-image{
		height: 670px;
		background-position: center;
		padding: 0;

		@include break(tablet){
			height: 600px;
		}

		@include break(mobile){
			height: 47vw;
		}
	}


	// faq
	.faq-block{
		margin-bottom: 0px;

		@include break(tablet){
			margin-bottom: 0px;
		}

		@include break(v-mobile){
			margin-bottom: 0px;
		}

		h2{
			margin-bottom: 40px;
		}

		ul.faq-list{
			list-style: none;

			& > li{
				margin-bottom: 24px;
				background: $white;
				border-radius: 8px;
				border: 1px solid $grey;
				transition: .3s;
				cursor: pointer;

				@include break(v-mobile){
					 margin-bottom: 14px;
				}

				&:hover{
					box-shadow: 0px 4px 8px rgba(7, 6, 11, 0.04);
				}

				&.active{
					box-shadow: 0px 4px 8px rgba(7, 6, 11, 0.04);

					.question{
						color: $green;

						.icon-holder{
							transform: translateY(-50%) rotate(45deg);
						}
					}
				}

				&:last-of-type {
					margin-bottom: 0px;
				}
			}

			.question{
				min-height: 105px;
				padding: 20px 180px 20px 40px;
				display: flex;
				align-items: center;
				position: relative;

				@include break(tablet){
					padding: 20px 100px 20px 40px;
					min-height: 90px;
				}

				@include break(mobile){
					padding: 20px 60px 20px 20px;
					min-height: 80px;
				}

				span.icon-holder{
					width: 28px;
					height: 28px;
					position: absolute;
					top: 50%;
					right: 40px;
					transform: translateY(-50%) rotate(0deg);
					display: block;
					transition: .3s;

					@include break(tablet){
						width: 24px;
						height: 24px;
						right: 30px;
					}

					@include break(v-mobile){
						width: 18px;
						height: 18px;
						right: 20px;
					}

					&:before,
					&:after{
						content: '';
						background: $dark;
						height: 4px;
						width: 100%;
						position: absolute;
						left: 0;
						top: 50%;
						display: block;

					}

					&:before{
						transform: translateY(-50%) rotate(90deg);
					}

					&:after{
						transform: translateY(-50%);
					}
				}
			}

			.answer{
				padding: 10px 80px 40px 40px;
				display: none;

				@include break(tablet){
					padding: 10px 35px 10px;
				}

				@include break(mobile){
					padding: 10px 20px 20px;
				}

				a{
					color: $green;
					text-decoration: underline;
				}

				p{
					margin-bottom: 20px;

					&:last-of-type{
						margin-bottom: 0;
					}
				}

				ul, ol{
					padding: 10px 0;

					li{
						margin-bottom: 8px;
					}
				}
			}
		}
	}
}
