.text-animation{
	background-color: $dark;
	padding: 50px 0;
	display: flex;
	white-space: nowrap;
	overflow: hidden;

	@include break(tablet){
		padding: 40px 0;
	}

	@include break(mobile){
		padding: 20px 0;
	}

	.text-loop{
		display: flex;
		align-items: center;
		animation: textLoop 20s linear infinite;

		h2{
			font-size: 100px;
			line-height: 1em;
			text-transform: uppercase;
			font-weight: 800;
			-webkit-text-stroke: 1px $white;
			color: $dark;
			text-shadow: 0px 0px 0 $white;

			@include break(tablet){
				font-size: 80px;
			}

			@include break(mobile){
				font-size: 60px;
			}
		}

		span.img-holder{
			width: 178px;
			display: inline-block;
			margin: 0 45px;

			@include break(tablet){
				width: 140px;
			}

			@include break(mobile){
				width: 60px;
			}

			img{
				max-width: 100%;
				height: auto;
				display: inline-block;
			}
		}
	}
}

.text-animation{
	&.loop-box-2{
		.text-loop{
			animation: textLoopinverted 20s linear infinite;
		}
	}
}

@keyframes textLoop {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
}

@keyframes textLoopinverted {
    0% {
         -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }
    100% {
         -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

