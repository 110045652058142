section.slider_testimonials{
	overflow: hidden;
	padding-bottom: 200px;
	padding-top: 70px;
	margin-top: 30px;
	// margin-bottom: 180px;
	position: relative;

	@include break(v-mobile){
		margin-top: 0;
	}

	@include break(v-mobile){
		padding-bottom: 88px;
	}

	.slider-wrapper {
		height: 350px;
		width: 100%;
		position: relative;

		@include break(v-mobile){
			height: 540px;
		}
	}

	.arrow-left {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(calc((-900px * 0.65) - 50%), -50%);
		z-index: 7;
		cursor: pointer;
	}
	.arrow-right {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(calc((900px * 0.65) - 50%), -50%);
		z-index: 7;
		cursor: pointer;
	}

	.slide {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-300%);
		opacity: 0;

		&:first-of-type {
			transform: translateX(-50%);
			opacity: 1;
			display: block;
		}
		&:last-of-type {
			transform: translateX(-165%);
			opacity: 0.2;
			display: block;
		}
		&:nth-of-type(2) {
			transform: translateX(65%);
			opacity: 0.2;
			display: block;
		}


		.icon1 {
			position: absolute;
			top: 0;
			left: 0;
			transform: translate(-25%, -70%);
			z-index: 1;
		}
		.icon2 {
			position: absolute;
			bottom: 0;
			right: 0;
			transform: translate(70%, 70%);
			z-index: 1;
		}

		.content {
			position: relative;
			z-index: 2;
			background-color: $dark;
			color: $white;
			border-radius: 8px;
			width: 900px;
			height: 400px;
			// height: auto;
			padding: 60px;
			display: flex;
			pointer-events: none;

			@include break(tablet){
				width: 700px;
			}

			@include break(mobile){
				width: 500px;
				height: auto;
				padding: 40px;
			}

			@include break(v-mobile){
				width: 330px;
				padding: 40px 25px;
			}

			.img {
				width: 140px;
				height: 140px;
				flex-shrink: 0;
				background-size: cover;
				background-position: center;
				margin-right: 5%;

				@include break(mobile){
					display: none;
				}
			}

			.txt {
				p {
					font-size: 1.25em;
					@include font-secondary-regular;

					@include break(mobile){
						font-size: 20px;
					}
				}

				.bottom {
					margin-top: 20px;
					padding-left: 10px;
					border-left: 1px solid $green;
					h4 {
						font-size: 1.15em;
						@include font-secondary-semibold;
					}
					h6 {
						font-size: 0.875em;
						@include font-secondary-regular;
					}
				}
			}
		}
	}

	.bullets_wrap {
		margin-left: auto;
		margin-right: auto;
		display: flex;
		position: absolute;
		bottom:80px;
		left: 50%;
		transform: translate(-50%, -50%);

		@include break(v-mobile){
			bottom: 44px;
		}

		.bullet {
			width: 14px;
			height: 14px;
			background-color: rgba($green,0);
			border: 1px solid $green;
			border-radius: 4px;
			margin-left: 6px;
			margin-right: 6px;
			transition: all 0.4s;
			cursor: pointer;
			&:hover, &.active {
				background-color: rgba($green,1);
			}
		}

	}

}


